import { FC, useState, useEffect, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import styles from './styles.module.scss'
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'var(--color_dialog_shadow)'
  },
  '& .MuiPaper-root': {
    backgroundColor: 'var(--color_dialog_background)',
    borderRadius: '1vw',
    maxWidth: 'none',
    width: '85vw',
    maxHeight: '80vh',
    overflow: 'hidden'
  },
}));


export interface Props {
  icon?: ReactNode,
  title?: string,
  content?: string,
  cancelText?: string,
  confirmText?: string,
  waitTime?: number,
  defaultResult?: boolean,
  closingResult?: boolean,
  open: boolean;
  onClose?: (result: boolean) => void;
}

const ConfirmDialog: FC<Props> = ({icon, title = '', content = '', cancelText='', confirmText='', waitTime=-1, defaultResult=false, closingResult=false, open, onClose}) => {
  const [counter, setCounter] = useState<number>(waitTime);
  const {t} = useTranslation();
  const header: string = title.length>0 ? title : t('confirm');
  const labelConfirm: string = confirmText.length>0 ? confirmText : t('yes');
  const labelCancel: string = cancelText.length>0 ? cancelText : t('no');

  useEffect(() => {
    if (open){
      setCounter(waitTime);
    }
  },[open, waitTime]);

  useEffect(() => {
    const to:NodeJS.Timeout | null = waitTime > 0 ?setTimeout(()=>{
      if(open&&counter>0)setCounter((c)=>c-1);
      if (open&&waitTime>0&&counter===0){
        setCounter(waitTime);
        if (onClose) onClose(defaultResult);
      }
      },1000):null;
    return () => {
      if (to) clearTimeout(to);
    };
  },[waitTime, counter, open, defaultResult, onClose]);

  const handleClose = (useResult:boolean): void => {
    if (onClose) onClose(useResult);
  };

  return (
    <BootstrapDialog onClose={() => {handleClose(closingResult)}} open={open}>
      <div className={styles.dialog} >
        {icon && (icon)}
      <div className={styles.header}>
        <h3>{header}</h3>
      </div>
      <div className={styles.container}>
        {content}
      </div>
      <div className={styles.paymentButton}>
        <button className={`button`} onClick={() => {handleClose(false)}}>
          {labelCancel} {(waitTime>0&&!defaultResult&&counter>0) && `(${counter})`}
        </button>
        <button className={`mainButton`} onClick={() => {handleClose(true)}}>
          {labelConfirm} {(waitTime>0&&defaultResult&&counter>0) && `(${counter})`}
        </button>
      </div>
      </div>
   </BootstrapDialog>
  );
};

export default ConfirmDialog;
