import { FC } from 'react';
import styles from './styles.module.scss'

export interface Props {
  count:number;
}

const Counter: FC<Props> = ({count}) => {

  return (
    <div className={styles.inactivityCounter}>
      {count}
    </div>
  );
};

export default Counter;
