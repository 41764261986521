import { FC, useContext } from 'react';
import SalePath from '../../../models/SalePath';
import { KioskContext } from '../../../context/KioskContext';
import { KioskActionsEnum } from "../../../context/KioskContext/types";
import styles from './styles.module.scss';
import Product from '../../../models/product';

interface SalePathNodeProps {
  node: SalePath
}

const SalePathNode: FC<SalePathNodeProps> = ({node}) => {
  const {dispatch} = useContext(KioskContext);

  const clickHandler = (): void => {
    dispatch({type: KioskActionsEnum.SELECT_NODE, salePath: node, product: Product});
  }

  const imageSource: string = node.imagePath;
  const label: string = node.name;

  return (
    <div className={`container ${styles.card}`} onClick={clickHandler}>
      <div className={styles.image}>
        {imageSource &&
          <img
            src={imageSource}
            alt=""
            id={node.id.toString()}
          />
        }
      </div>
      <div className={styles.name}>
        <span>{label.toUpperCase()}</span>
      </div>
    </div>
  );
};

export default SalePathNode;
