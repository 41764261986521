import { FC, useContext,  ReactNode } from 'react';
import styles from './styles.module.scss'
import { useTranslation } from "react-i18next";
import Product from '../../../models/product';
import DisplayProduct from '../DisplayProduct';
import { KioskContext } from '../../../context/KioskContext';
import { formatMoney } from "../../../helpers/formatHelper";

export interface Props {
  onModify?: (product: Product) => void;
  onRemove?: (product: Product) => void;
}

const BasketProductList: FC<Props> = ({onModify, onRemove}) => {
  const { state } = useContext(KioskContext);
  const {t} = useTranslation();

  const doModify = (product: Product) => {
    if (onModify)onModify(product);
  }
  const doRemove = (product: Product) => {
    if (onRemove)onRemove(product);
  }

  const renderItem = (): ReactNode[] | undefined => {
    if (state.cart?.products.length===0)return;
    
    return state.cart?.products.map((i: Product, idx: number) => {
      return (
      <div key={idx} className={styles.item}>
        <DisplayProduct key={idx} product={i} onModify={onModify?doModify:undefined} onRemove={onRemove?doRemove:undefined} />
      </div>
      );
    });
  }

  return (
    <div className={styles.productList}>
      <div className={styles.container}>
        {renderItem()}
      </div>
      <div className={styles.totals}>
        <div className={styles.label}>{t('total')}</div>
        <div className={styles.amount}>{formatMoney(state.cart?.receiptTotal)} €</div>
      </div>
    </div>
  );
};

export default BasketProductList;
