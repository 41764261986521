import CodeName from "./CodeName";

export default class IDCodeName extends CodeName {
  id: number;

  constructor(id: number, code: string, name: string) {
    super(code, name)
    this.id = id;
  }
}
