import { useContext, useEffect, useState } from 'react';
import { KioskContext } from "../../../../context/KioskContext";
import styles from './styles.module.scss';
import { KioskActionsEnum } from "../../../../context/KioskContext/types";
import { useTranslation } from "react-i18next";
import ILocalSettings from '../../../../models/localSettings';
import { getLocalSettings } from '../../../../helpers/localStorageHelper';
import { DeliveryMethodEnum } from '../../../../helpers/basketApi';

const settings:ILocalSettings = getLocalSettings();
const waitTime:number = settings.timerButton;

export const SelectOrderNumber = () => {
  const {t, i18n} = useTranslation();
  const {state, dispatch} = useContext(KioskContext);
  //const inputRef = useRef<HTMLInputElement>(null);
  const [counter, setCounter] = useState<number>(waitTime);

  const deliveryMethod: DeliveryMethodEnum = state.cart?.deliveryMethod||DeliveryMethodEnum.FrontCounter;
  const instructionSource:string = (deliveryMethod === DeliveryMethodEnum.TableServing || deliveryMethod === DeliveryMethodEnum.TakeawayTableServing)?'table':'counter';
  const instruction: {id: number, title: string}[] = [
    {id: 1, title: t(`instruction-${instructionSource}.first`)},
    {id: 2, title: t(`instruction-${instructionSource}.second`)},
    {id: 3, title: t(`instruction-${instructionSource}.third`)},
  ]

  const orderNumber: string = state.cart?.serviceID || '<n/a>';

  useEffect(() => {
    const to:NodeJS.Timeout | null = waitTime > 0 ?setTimeout(()=>{
      if(counter>0)setCounter((c)=>c-1);
      if (waitTime>0&&counter===0){
        setCounter(waitTime);

        dispatch({type: KioskActionsEnum.ORDER_COMPLETE});
        i18n.changeLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);

      }
      },1000):null;
    return () => {
      if (to) clearTimeout(to);
    };
  },[counter, dispatch, i18n]);

  const handleFinishOrder = (): void => {
    dispatch({type: KioskActionsEnum.ORDER_COMPLETE});
    i18n.changeLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);
  };

  return (
    <div className={styles.selectOrderNumber}>
      {t('the-order-is-paid')}
      <div className={styles.content}>
        <div className={styles.waitingNumber}>
          <span>{t('pickup-number')}</span>
          <div className={`container ${styles.waitingNumberContainer}`}>
            <input
              //ref={inputRef}
              type="number"
              value={orderNumber}
              disabled={true}
            />
          </div>
        </div>

        <div className={styles.instruction}>
          {instruction.flatMap(({id, title}) => {
            if (title==='')return null;
            return (
            <div key={id} className={styles.instructionItem}>
              <div style={{width: 'min-content'}}>
                <div className={styles.instructionBall}>{id}</div>
              </div>
              <span>{title}</span>
            </div>
            );
          }
          )}
        </div>
      </div>

      <div className={styles.button}>
        <div>
          {t('enjoy-your-meal')}
        </div>
        <button className={`mainButton`} onClick={handleFinishOrder}>{t('button.next-customer')}{(counter>0) ? ` (${counter})` : ''}</button>
      </div>
    </div>
  );
};
