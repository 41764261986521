import { FC, useContext, useState } from 'react';
import styles from './styles.module.scss';
import { KioskActionsEnum } from "../../../../context/KioskContext/types";
import { KioskContext } from "../../../../context/KioskContext";
import { useTranslation } from "react-i18next";
import {
  setDeliveryMethod,
  DeliveryMethodEnum,
} from "../../../../helpers/basketApi";


interface Props {
  
}

export const SelectPlaceStep: FC<Props> = () => {
  const {t} = useTranslation();
  const { state, dispatch } = useContext(KioskContext);
  
  const isMethodInUse = (method: DeliveryMethodEnum) : boolean => {
    if (state.cart?.posParameters){
      const prm = state.cart.posParameters.find(p => p.parameterCode.toLowerCase()===`basketdelivery-${method.toLowerCase()}-inuse`);
      if (prm)return (prm.parameterValue === '1' || prm.parameterValue.toLowerCase() === 'true');
    }
    return true;
  };

  const selectOrderPlace = async (selectedPlace: DeliveryMethodEnum) => {
    if (state.cart){
      const cart = await setDeliveryMethod(state.cart.id, selectedPlace);
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      dispatch({type: KioskActionsEnum.SELECT_NEXT_STEP})
    }
  };
  
  const [allowOnPremise] = useState<boolean>(isMethodInUse(DeliveryMethodEnum.FrontCounter) || isMethodInUse(DeliveryMethodEnum.TableServing))
  const [allowTakeaway] = useState<boolean>(isMethodInUse(DeliveryMethodEnum.Takeaway) || isMethodInUse(DeliveryMethodEnum.TakeawayTableServing))

  if (allowOnPremise && !allowTakeaway){
    selectOrderPlace(DeliveryMethodEnum.FrontCounter);
  }
  if (!allowOnPremise && allowTakeaway){
    selectOrderPlace(DeliveryMethodEnum.Takeaway);
  }
  
  return (
    <div className={styles.selectPlace}>
      {allowOnPremise && (
        <div 
          className={`container ${styles.placeCard}`} 
          onClick={() => selectOrderPlace(DeliveryMethodEnum.FrontCounter)}
        >
          <span>{t('button.inside')}</span>
        </div>
      )}
      {allowTakeaway && (
        <div 
          className={`container ${styles.placeCard}`} 
          onClick={() => selectOrderPlace(DeliveryMethodEnum.Takeaway)}
        >
          <span>{t('button.away')}</span>
        </div>
      )}
    </div>
  );
};