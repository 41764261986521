import { useState, useEffect } from 'react';

export default function InactivityTimer () {

  const [count, setCount] = useState<number>(0);
  const maxValue:number = 3600;

  useEffect(() => {
    const tick = () => {
      if (count>=maxValue){
        setCount(0);
      } else {
        setCount((c) => c+1);
      }
    }
    const timeoutId:NodeJS.Timeout = setTimeout(() => tick(),1000);
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  },[count]);

  const reset = () => {
    setCount(0);
  }

  return  { inactivitySeconds: count, inactivityTimerReset: reset };
}
