import { FC } from 'react';
import styles from './styles.module.scss'

export interface Props {
  text:string;
}

const BottomInfo: FC<Props> = ({text}) => {

  return (
    <div className={styles.bottomInfo}>
      {text}
    </div>
  );
};

export default BottomInfo;
