import { useState } from 'react';

export default function Config () {

  const debugKey:string = 'debug-mode';

  const [version] = useState<string>(`${process.env.REACT_APP_BRANCH||''} (${process.env.REACT_APP_COMMIT||''})`);

  const debugMode:boolean = document.documentElement.getAttribute(debugKey)!=null;

  const toggleDebugMode = () => {
    const currentValue:boolean = document.documentElement.getAttribute(debugKey)!=null;
    if (currentValue){
      document.documentElement.removeAttribute(debugKey);
      console.log('DEBUG OFF');
    } else {
      document.documentElement.setAttribute(debugKey, 'enabled')
      console.log('DEBUG ON');
    }
  }
  
  return  { version: version, debugMode: debugMode, toggleDebugMode: toggleDebugMode };
}
