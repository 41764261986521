export default interface ILocalSettings {
  shopCode: string;
  posCode: string;
  timerFront: number;
  timerButton: number;
  timerInactivity: number;
  timerReload: number;
}

export const initialLocalSettings: ILocalSettings = {
  shopCode: '',
  posCode: '',
  timerFront: 20,
  timerButton: 15,
  timerInactivity: 60,
  timerReload: 60 * 30,
};
