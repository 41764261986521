import { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import SalePathProductTitle from '../SalePathProductTitle';
import SalePathProductMainOption from '../SalePathProductMainOption';
import { KioskActionsEnum } from "../../../context/KioskContext/types";
import styles from './styles.module.scss';
import Cart from "../../../models/cart";
import { useTranslation } from "react-i18next";
import {
  initializeCart,
  addProductRow,
  deleteProductRow,
} from "../../../helpers/basketApi";
import EditItem from '../../../models/EditItem';
import { formatMoney } from "../../../helpers/formatHelper";

interface Props {
  node: EditItem
}

const SalePathProduct: FC<Props> = ({node}) => {
  const {t} = useTranslation();
  const {state, dispatch} = useContext(KioskContext);
  const [isNeedUpdate, setIsNeedUpdate] = useState<boolean>(false);

  useEffect((): void => {
    if (isNeedUpdate) {
      setIsNeedUpdate(false);
    }
  }, [isNeedUpdate]);

  const setProductCount = (count: number) => {
    if (count<1)count=1;
    if (count>9)count=9;

    node.quantity = count;
    setIsNeedUpdate(true);
  }

  const addProductToBasket = async () => {
    if (state.settings!=null){
      var cart: Cart | null = state.cart;
      if (node.product?.rowId){
        if (cart!=null){
          cart = await deleteProductRow(cart.id, node.product?.rowId);
          cart = await addProductRow(cart.id, node.generateProductData());
        }
      } else {
        if (state.cart==null){
          cart = await initializeCart(state.settings);
        }
        if (cart!=null){
          cart = await addProductRow(cart.id, node.generateProductData());
        }
      }
      node.reset();
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      dispatch({ type: KioskActionsEnum.SELECT_PARENT});
      dispatch({type: KioskActionsEnum.SHOW_BASKET});      
    }
  }

  const mainOptions: ReactNode = useMemo(() => {
    return node.children
      .filter(c => c.salePath.isChainProductDefined)
      .map((subItem: EditItem, index: number) => (
        <SalePathProductMainOption key={index} item={subItem} />
      ));
  }, [node]);

  const price : string = `${formatMoney(
    node.quantity * (node.product==null?(
    node.salePath.salePrice + node.getForcedPrices()
  ):(
    node.getForcedPrices()
  ))
  )} €`

  return (
    <div className={styles.selectedProduct}>
    {node.salePath.isMainProduct() && (
        <>
          <div className={styles.content}>
          <SalePathProductTitle item={node} isNeedUpdate={setIsNeedUpdate} />
            <div className={styles.moreOptionsContainer}>
              {!node.children.some(item => item.salePath.isContent) && mainOptions}
            </div>
          </div>

          <div className={styles.countAndButton}>
            <div className={styles.count}>
              <span>{t('count')}</span>
              <div className={styles.countButtons}>
              <button onClick={() => setProductCount(node.quantity - 1)}>-</button>
              <span className={styles.countText}>{node.quantity}</span>
              <button onClick={() => setProductCount(node.quantity + 1)}>+</button>
              </div>
            </div>

            <div className={styles.addToBasketButton}>
              <button
                type='button'
                className='mainButton'
              onClick={addProductToBasket}
              >
              {node.product ? `${t('button.update-basket')} ${price}` : `${t('button.add-basket')} ${price}`}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SalePathProduct;