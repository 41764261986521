import EditItem from "./EditItem";
import SalePath from "./SalePath";
import Product from "./product";

export default class EditData {

  rootItem: EditItem;
  product: Product | null = null;

  constructor(
    rootItem: SalePath,
    product?: Product | null
    ) {
      if (product) this.product = product;
      this.rootItem = new EditItem(rootItem, this.product);
  }

  public findBySalePathCode = (salePathCode: string, item?: EditItem | null) : EditItem | null => {
    if (item){
      if (item.salePath.code===salePathCode) return item;
      item.children.forEach((child: EditItem) => {
        const result = this.findBySalePathCode(salePathCode, child);
        if (result) return result;
      });
      return null;
    }
    else
    {
      return this.findBySalePathCode(salePathCode, this.rootItem);
    }
  }
}
