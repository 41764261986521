import { FC, useContext, useState } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { BasketEnum, KioskActionsEnum } from "../../../context/KioskContext/types";
import styles from './styles.module.scss'
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import Product from '../../../models/product';
import { deleteProductRow } from "../../../helpers/basketApi";
import BasketProductList from '../BasketProductList';
import ConfirmDialog from '../ConfirmDialog';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'var(--color_dialog_shadow)',
  },
  '& .MuiPaper-root': {
    backgroundColor: 'var(--color_dialog_background)',
    borderRadius: '0.8vw',
    maxWidth: 'none',
    width: '86vw',
    maxHeight: '83vh',
    overflow: 'hidden',
    margin: 0
  },
}));


export interface Props {
  open: boolean;
  onClose?: () => void;
}

const BasketDialog: FC<Props> = ({open, onClose}) => {
  const {t} = useTranslation();
  const { state, dispatch } = useContext(KioskContext);
  const [ productToRemove, setProductToRemove ] = useState<Product|null>(null);

  const handleClose = (): void => {
    dispatch({ type: BasketEnum.HIDE_BASKET });
    if (onClose) onClose();
  };
  
  const handleChange = (product: Product): void => {
    const salePath = state.salePathData.findBySalePathCode(product.salePathCode);
    //console.log('handleChange', salePath, product);
    dispatch({type: KioskActionsEnum.SELECT_NODE, salePath, product});
    handleClose();
  }

  const handleRemove = (product: Product) => {
    setProductToRemove(product);
  }

  const continueOrder = (): void => {
    dispatch({ type: KioskActionsEnum.SELECT_NEXT_STEP, step: 0 });
    //dispatch({ type: KioskActionsEnum.SELECT_PARENT});
    dispatch({ type: BasketEnum.HIDE_BASKET });
  };

  const startPayment = (): void => {
    dispatch({type: KioskActionsEnum.SELECT_NEXT_STEP, step: 1})
    dispatch({ type: BasketEnum.HIDE_BASKET });
  };

  const confirmRemove = async (remove: boolean) => {
    if (!remove){
      setProductToRemove(null);
      return;
    }
    if (state.cart!=null && productToRemove){
      const cart = await deleteProductRow(state.cart.id, productToRemove.rowId);
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      setProductToRemove(null);
      if (cart.products.length===0){
        handleClose();
      }
    } else {
      setProductToRemove(null);
    }
  };

  return (
    <BootstrapDialog onClose={continueOrder} open={open}>
      <div className={styles.header}>
        <h3>{t('your-order')}</h3>
        <div onClick={handleClose} className={styles.closeBtn}>
          <ClearIcon fontSize={"large"} fontSizeAdjust={22}/>
        </div>
      </div>
      <BasketProductList onModify={(i) => handleChange(i)} onRemove={(i) => handleRemove(i)} />
      <div className={styles.paymentButton}>
      <button className={`button`} onClick={continueOrder}>
          {t('button.continue-order')}
        </button>
        <button className={`mainButton`} onClick={startPayment}>
          {t('button.proceed-to-payment')}
        </button>
      </div>
      <ConfirmDialog
        icon={<DeleteOutlinedIcon/>}
        title={t('remove-product')}
        confirmText={t('button.remove')}
        cancelText={t('button.cancel')}
        open={productToRemove!=null} onClose={(r) => {confirmRemove(r)}} />
   </BootstrapDialog>
  );
};

export default BasketDialog;
