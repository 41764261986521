import { FC, MouseEventHandler, useState } from 'react';
import styles from './styles.module.scss';
import DoneIcon from '@mui/icons-material/Done';
import SalePath from "../../../../models/SalePath";
import { formatMoney } from "../../../../helpers/formatHelper";
import { useTranslation } from "react-i18next";

interface Props {
  showPrice?: boolean
  node: SalePath,
  units: number;
  disabled?: boolean;
  canIncrease?: boolean;
  onChanged?: (units: number) => void;
}

const OptionSwitch: FC<Props> = ({node, showPrice, units, disabled = false, onChanged}) => {
  const [value, setValue] = useState<boolean>(units !== 0);
  const {t} = useTranslation();

  const handleChange: MouseEventHandler<HTMLDivElement> = (): void => {
    onChange(!value);
  };

  const onChange = (newValue: boolean): void => {
    setValue(newValue);
    if (onChanged) onChanged(newValue ? 1 : 0);
  }

  return (
    <div 
      className={`container ${styles.optionSwitch} ${value ? styles.checked: ''}`}
      onClick={!disabled ? handleChange : undefined}
    >
      <div className={styles.priceWithName}>
        <p>{node.name}</p>
        {showPrice && <span>+ {formatMoney(node.salePrice)} €</span>}
      </div>
      <div className={styles.required}>
        {disabled && <span>{t('mandatory-choice')}</span>}
        {value && <span><DoneIcon/></span>}
      </div>
    </div>
  );
};
export default OptionSwitch;
