import { FC } from "react";
import SalePathProductOption from "../../SalePathProductOption";
import Dialog from '@mui/material/Dialog';
import styles from './styles.module.scss';
import { styled } from "@mui/material/styles";
import EditItem from "../../../../models/EditItem";
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    backgroundColor: 'var(--color_dialog_background)'
  },
}));

export interface Props {
  open: boolean;
  item: EditItem;
  onClose: (bool: boolean) => void;
}

const ProductOptionDialog: FC<Props> = ({open, item, onClose}) => {
  const {t} = useTranslation();
  
  const cancel = () => {
    onClose(false);
  }
  const accept = () => {
    onClose(true);
  }

  return (
    <BootstrapDialog 
      fullScreen 
      onClose={() => cancel()} 
      open={open} 
      className={styles.productOptionDialog}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div style={{marginTop: "3vw"}}>
          </div>
          <SalePathProductOption item={item} onClose={onClose}/>
        </div>
        <div className={styles.buttonContent}>
          <button 
            type='button' 
            className='mainButton' 
            onClick={() => accept()}
          >
            {t('button.ready')}
          </button>
        </div>
      </div>
    </BootstrapDialog>
  );
};

export default ProductOptionDialog;

