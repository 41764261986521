import { FC } from 'react';

import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import styles from './styles.module.scss'
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import LanguageChoice from '../LanguageChoice';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'var(--color_dialog_shadow)'
  },
  '& .MuiPaper-root': {
    backgroundColor: 'var(--color_dialog_background)',
    borderRadius: '1vw',
    maxWidth: 'none',
    width: '90vw',
    maxHeight: '82vh',
    overflow: 'hidden'
  },
}));

export interface Props {
  open: boolean;
  onClose?: (result: string) => void;
}

const LanguageDialog: FC<Props> = ({open, onClose}) => {
  const {t, i18n} = useTranslation();

  const handleClose = (): void => {
    if (onClose) onClose(i18n.language);
  };

  const onChange = (language: string): void => {
    handleClose();
  };

  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <div className={styles.header}>
        <h3>{t('changeLanguage')}</h3>
        <div onClick={() => {handleClose()}} className={styles.closeBtn}>
          <ClearIcon fontSize={"large"} fontSizeAdjust={22}/>
        </div>
      </div>
      <div className={styles.container}>
        <LanguageChoice onChange={(l:string) => {onChange(l)}}/>
      </div>
   </BootstrapDialog>
  );
};

export default LanguageDialog;
