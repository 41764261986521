import { FC, useCallback, useContext, useEffect, useState, memo } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import { getSalePaths } from '../../../helpers/searchApi';
import SalePath from "../../../models/SalePath";
import { KioskActionsEnum } from "../../../context/KioskContext/types";
import { useTranslation } from "react-i18next";
import { getLocalSettings } from '../../../helpers/localStorageHelper';
import ConfirmDialog from '../../components/ConfirmDialog';
import ILocalSettings from '../../../models/localSettings';
import KioskClosed from '../../components/KioskClosed';
import InactivityTimer from '../../../hooks/InactivityTimer';
import KioskOpen from '../../components/KioskOpen';
import ScreenSize from '../../components/ScreenSize';
import Counter from '../../components/Counter';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import BottomInfo from '../../components/BottomInfo';
import Config from '../../../hooks/Config';
import ThemeChoice from '../../components/ThemeChoice';
import DebugToggle from '../../components/DebugToggle';
import Header from '../../components/Header';
import KioskFront from '../../components/KioskFront';
import useUpdate from '../../../hooks/useUpdate';

const defaultTheme:string = 'light';


interface MemoProps {
  updated: Date;
  showFront: boolean;
  returnedFront: boolean;
}

const KioskContent = memo<MemoProps>(({updated, showFront, returnedFront}) => {
  //Outside of rendering executed by 1 sec. timer
  if (showFront){
    return (
    <>
      <KioskFront />
    </>);
  }
  return (
    <>
      <Header />
      <KioskOpen returnedFront={returnedFront}/>
    </>
  );
});

const Browse: FC = () => {
  const {state, dispatch} = useContext(KioskContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inactive, setInactive] = useState<boolean>(false);
  const [showFront, setShowFront] = useState<boolean>(false);
  const [returnedFront, setReturnedFront] = useState<boolean>(false);
  const [contentUpdated, setContentUpdated] = useState<Date>(new Date());
  const [openingHoursCheck, setOpeningHoursCheck] = useState<number>(-1);
  const {t, i18n} = useTranslation();
  const settings:ILocalSettings = getLocalSettings();
  const {inactivitySeconds, inactivityTimerReset } = InactivityTimer();
  const {version, debugMode } = Config();
  const { updateExists, checkForUpdate } = useUpdate();

  useEffect((): void => {
    // Setting default environment values
    document.documentElement.setAttribute('data-theme', defaultTheme)
    if (Boolean(JSON.parse(process.env.REACT_APP_DEBUG||'0'))){
      document.documentElement.setAttribute('debug-mode', 'enabled')
    }
  }, [])

  useEffect(() => {
    if (state.salePath == null){
      const minutes:number = new Date().getMinutes();
      if (openingHoursCheck !== minutes){
        setOpeningHoursCheck(minutes);
        if (state.salePathData?.checkVisibility()){
          setContentUpdated(new Date());
        }
      }
    }
    if ((state.cart||state.salePath)&&!inactive){
      if (settings.timerInactivity > 0 && inactivitySeconds >= settings.timerInactivity){
        setInactive(true);
      }
    } else {
      if (inactivitySeconds >= 5 && updateExists && state.cart == null && state.salePath == null){
        window.location.reload();
      }
      if (state.salePath==null && !inactive && inactivitySeconds >= 15 && i18n.language !== process.env.REACT_APP_DEFAULT_LANGUAGE){
        i18n.changeLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);
      }
      if (settings.timerReload > 0 && inactivitySeconds >= settings.timerReload){
        checkForUpdate();
        inactivityTimerReset();
        dispatch({type: KioskActionsEnum.RELOAD});
      }
      if (showFront === false && state.salePath==null && settings.timerFront > 0 && inactivitySeconds > settings.timerFront){
        setShowFront(true);
      }
      if (showFront === true && (state.cart == null || state.cart.products.length === 0) && inactivitySeconds < 15 ){
        setReturnedFront(true);
        setShowFront(false);
      } else {
        setReturnedFront(false);
      }
    }
  },[state.cart, state.salePath, state.salePathData, dispatch, inactive, inactivitySeconds, inactivityTimerReset, openingHoursCheck, i18n, showFront, settings, checkForUpdate, updateExists]);

  const fetchSalePaths = useCallback(async (shopCode: string): Promise<void> => {
    dispatch({type: KioskActionsEnum.SELECT_ROOT});
    const data: SalePath[] = await getSalePaths(shopCode, i18n.language);
    dispatch({type: 'LOADED', salePaths: data});
  }, [dispatch, i18n.language])

  useEffect((): void => {
    if (!state.isDataLoaded && !isLoading) {
      if (state.settings && state.settings.shopCode?.length > 0) {
        setIsLoading(true);
        fetchSalePaths(state.settings.shopCode);
        setIsLoading(false);
      }
    }
  }, [state.settings, state.isDataLoaded, isLoading, dispatch, fetchSalePaths])

  useEffect(() => {
    const disableRightClick = (event: MouseEvent): void => {
      inactivityTimerReset();
      event.preventDefault();
    };
    const handleTouchMove = (event: TouchEvent): void => {
      inactivityTimerReset();
      //event.preventDefault();
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };
    const handleTouchStart = (event: TouchEvent): void => {
      inactivityTimerReset();
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('contextmenu', disableRightClick);
    return (): void => {
      document.removeEventListener('contextmenu', disableRightClick);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchstart', handleTouchStart);
    }
  }, [inactivityTimerReset]);

  const inactivityDialogClosed = (result: boolean) => {
    inactivityTimerReset();
    setInactive(false);
    if (!result){
      i18n.changeLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);
      dispatch({type: KioskActionsEnum.TIMEOUT});
    }
  };

  const isClosed: boolean = state.salePathData?.isClosed() || false;
  //const isClosed: boolean = true;

  if (isClosed){
    return (<KioskClosed/>);
  }

  const theme:string = document.documentElement.getAttribute('data-theme')||defaultTheme;

  const backgroundImageStyle = {
    backgroundImage: `url(${process.env.REACT_APP_BASKET_IMAGE_URI + `/BasketKiosk/Background-${theme}.png`})`,
    backgroundSize: 'cover',
  };

  // Rendered by 1 sec. interval
  return (
    <div style={backgroundImageStyle}>
      {debugMode && (
        <>
          <ScreenSize/>
          <Counter count={inactivitySeconds}/>
          <ThemeChoice />
          <DebugToggle />
          <BottomInfo text={version}/>
        </>
      )}
      <KioskContent updated={contentUpdated} showFront={showFront} returnedFront={returnedFront}/>
      <ConfirmDialog
        icon={<WavingHandIcon/>}
        title={t('confirm-continue')}
        content={t('inactivity-info')}
        waitTime={settings.timerButton}
        cancelText={t('button.return-to-start')}
        confirmText={t('button.continue-order')}
        defaultResult={false} closingResult={true} open={inactive} onClose={inactivityDialogClosed}>
      </ConfirmDialog>
    </div>
  );
};

export default Browse;
