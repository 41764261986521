import { FC, useContext } from 'react';
import styles from './styles.module.scss'
import { useTranslation } from "react-i18next";
import { KioskContext } from '../../../context/KioskContext';
import { KioskActionsEnum } from '../../../context/KioskContext/types';

export interface Props {
  onChange?: (result: string) => void;
}

const LanguageChoice: FC<Props> = ({onChange}) => {
  const {dispatch} = useContext(KioskContext);
  const {t, i18n} = useTranslation();

  const select = (language: string): void => {
    i18n.changeLanguage(language);
    if (onChange)onChange(i18n.language);
    dispatch({ type: KioskActionsEnum.RELOAD });
  };

  return (
    <div className={styles.languageChoice}>
      <div className={styles.languageButtons}>
        <button className={i18n.language==='fi'?'mainButton':'button'} onClick={() => {select('fi')}}>{t('languages.fi')}</button>
        <button className={i18n.language==='se'?'mainButton':'button'} onClick={() => {select('se')}}>{t('languages.se')}</button>
        <button className={i18n.language==='en'?'mainButton':'button'} onClick={() => {select('en')}}>{t('languages.en')}</button>
      </div>
    </div>
  );
};

export default LanguageChoice;
