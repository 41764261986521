export default class Product {
  rowId: string;

  productCode: string;

  productName: string;

  infoText: string;

  productInfo: string;

  quantity: number;

  salePrice: number;

  totalAmount: number;

  discountTotalAmount: number;

  totalAmountWithDiscount: number;

  productTypeCode: string;

  isPriceCompulsory: boolean;

  isSaleDisAllowed: boolean;

  isHandPriceAllowed: boolean;

  isSale: boolean;

  isDiscountAllowed: boolean;

  isContent: boolean;

  products: Array<Product>;

  conseptName: string;

  salePathCode: string;

  constructor(
    rowId: string,
    productCode: string,
    productName: string,
    infoText: string,
    productInfo: string,
    quantity: number,
    salePrice: number,
    totalAmount: number,
    discountTotalAmount: number,
    totalAmountWithDiscount: number,
    productTypeCode: string,
    isPriceCompulsory: boolean,
    isSaleDisAllowed: boolean,
    isHandPriceAllowed: boolean,
    isSale: boolean,
    isDiscountAllowed: boolean,
    isContent: boolean,
    products: Array<Product>,
    conseptName: string,
    salePathCode: string,
  ) {
    this.rowId = rowId;
    this.productCode = productCode;
    this.productName = productName;
    this.infoText = infoText;
    this.productInfo = productInfo;
    this.quantity = quantity;
    this.salePrice = salePrice;
    this.totalAmount = totalAmount;
    this.discountTotalAmount = discountTotalAmount;
    this.totalAmountWithDiscount = totalAmountWithDiscount;
    this.productTypeCode = productTypeCode;
    this.isPriceCompulsory = isPriceCompulsory;
    this.isSaleDisAllowed = isSaleDisAllowed;
    this.isHandPriceAllowed = isHandPriceAllowed;
    this.isSale = isSale;
    this.isDiscountAllowed = isDiscountAllowed;
    this.isContent = isContent;
    this.products = products;
    this.conseptName = conseptName;
    this.salePathCode = salePathCode;
  }

  public static cumulatedTotalAmount = (product: Product) : number => {
    var amount:number = product.totalAmount;
    product.products.forEach((p) => {
      amount += this.cumulatedTotalAmount(p);
    });
    return amount;
  }

}
