import { CSSProperties, FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import ILocalSettings, { initialLocalSettings } from '../../../models/localSettings';
import { KioskContext } from '../../../context/KioskContext';
import { FormControl } from '@mui/base/FormControl';
import TextField from '@mui/material/TextField';
import { KioskActionsEnum } from '../../../context/KioskContext/types';
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';

const Settings: FC = () => {
  const {state, dispatch} = useContext(KioskContext);
  const [shopCode, setShopCode] = useState<string>(initialLocalSettings.shopCode);
  const [posCode, setPosCode] = useState<string>(initialLocalSettings.posCode);
  const [timerFront, setTimerFront] = useState<number>(initialLocalSettings.timerFront);
  const [timerButton, setTimerButton] = useState<number>(initialLocalSettings.timerButton);
  const [timerInactivity, setTimerInactivity] = useState<number>(initialLocalSettings.timerInactivity);
  const [timerReload, setTimerReload] = useState<number>(initialLocalSettings.timerReload);
  const {t} = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    const values : ILocalSettings = state.settings||initialLocalSettings;
    setShopCode(values.shopCode);
    setPosCode(values.posCode);
    setTimerButton(values.timerButton);
    setTimerInactivity(values.timerInactivity);
    setTimerReload(values.timerReload);
  },[state.settings, setShopCode, setPosCode]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const newSettings : ILocalSettings = {
      shopCode: shopCode,
      posCode: posCode,
      timerFront: timerFront,
      timerButton: timerButton,
      timerInactivity: timerInactivity,
      timerReload: timerReload,
    };
    dispatch({type: KioskActionsEnum.SET_SETTINGS, settings: newSettings});
    navigate('/');
  }

  const parseNumber = (value: string, defaultValue?: number) : number => {
    const numb:number = Number.parseInt(value);
    return validateNumber(numb, defaultValue);
  }
  const validateNumber= (value: number, defaultValue?: number) : number => {
    if (isNaN(value)){
      return defaultValue===undefined ? 0 : defaultValue;
    }
    return value;
  }

  const labelStyle = () : CSSProperties => {
    return {
      fontSize: '3vw',
      marginLeft: '1vw',
    };
  }
  const fieldStyle = () : CSSProperties => {
    return {
      fontSize: '5vw',
      marginLeft: '1vw',
    };
  }
  const buttonStyle = () : CSSProperties => {
    return {
      fontSize: '5vw'
    };
  }
  
  return (
    <>
      <div className={styles.layout}>
        <h2>{t('settings')}</h2>

        <form onSubmit={handleSubmit}  className={styles.form}>
        <FormControl className={styles.controls}>
        <Paper className={styles.paper}>
            <div>
              <TextField name='shopCode' type="text" variant='standard' label={t('shop-code')}
                value={shopCode} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setShopCode(event.target.value);}}
                inputProps={{style: fieldStyle()}} InputLabelProps={{style: labelStyle()}}
              />
            </div>
            <div>
              <TextField name='posCode' type="text" variant='standard' label={t('pos-code')}
                value={posCode} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setPosCode(event.target.value);}} className={styles.textField}
                inputProps={{style: fieldStyle()}} InputLabelProps={{style: labelStyle()}}
                />
            </div>
          </Paper>
          <Paper className={styles.paper}>
            <div>
              <TextField name='timerFront' type="text" variant='standard' label={t('timer-front')}
                value={timerFront} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setTimerFront(parseNumber(event.target.value, 0));}} className={styles.textField}
                inputProps={{style: fieldStyle()}} InputLabelProps={{style: labelStyle()}}
                />
            </div>
            <div>
              <TextField name='timerButton' type="text" variant='standard' label={t('timer-button')}
                value={timerButton} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setTimerButton(parseNumber(event.target.value, 0));}} className={styles.textField}
                inputProps={{style: fieldStyle()}} InputLabelProps={{style: labelStyle()}}
                />
            </div>
            <div>
              <TextField name='timerInactivity' type="text" variant='standard' label={t('timer-inactivity')}
                value={timerInactivity} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setTimerInactivity(parseNumber(event.target.value, 0));}} className={styles.textField}
                inputProps={{style: fieldStyle()}} InputLabelProps={{style: labelStyle()}}
                />
            </div>
            <div>
              <TextField name='timerReload' type="text" variant='standard' label={t('timer-reload')}
                value={timerReload} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setTimerReload(parseNumber(event.target.value, 0));}} className={styles.textField}
                inputProps={{style: fieldStyle()}} InputLabelProps={{style: labelStyle()}}
                />
            </div>
          </Paper>
          <div className={styles.footer}>
            <Button type='submit' style={buttonStyle()} >{t('button.confirm')}</Button>
          </div>
        </FormControl>
        </form>
      </div>
    </>
  );
};

export default Settings;
