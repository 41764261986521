import { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import styles from './styles.module.scss'
import { useTranslation } from "react-i18next";
import Icon from '@mui/icons-material/ErrorOutline';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'var(--color_dialog_shadow)'
  },
  '& .MuiPaper-root': {
    backgroundColor: 'var(--color_dialog_background)',
    borderRadius: '1vw',
    maxWidth: 'none',
    width: '76vw',
    maxHeight: '80vh',
    overflow: 'hidden'
  },
}));


export interface Props {
  open: boolean;
  onTryAgain?: () => void;
  onReturn?: () => void;
}

const OrderPaymentErrorDialog: FC<Props> = ({open, onTryAgain, onReturn}) => {
  const {t} = useTranslation();

  const handleClose = (): void => {
    doReturn();
  };
  const doTryAgain = (): void => {
    if (onTryAgain)onTryAgain();
  };
  const doReturn = (): void => {
    if (onReturn)onReturn();
  };

  return (
    <BootstrapDialog onClose={() => {handleClose()}} open={open}>
      <div className={styles.header}>
        <Icon/>
        <h1>{t('pay-error')}</h1>
        <h3>{t('pay-error-info')}</h3>
      </div>
      <div className={styles.paymentButton}>
        <button className={`button`} onClick={doReturn}>{t('button.return')}</button>
        <button className={`mainButton`} onClick={doTryAgain}>{t('button.retry')}</button>
      </div>
   </BootstrapDialog>
  );
};

export default OrderPaymentErrorDialog;
