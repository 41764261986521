import OpeningHours from "./openingHours";

export default class SalePathOpeningHours {

  public monHours: OpeningHours | null;
  public tueHours: OpeningHours | null;
  public wedHours: OpeningHours | null;
  public thuHours: OpeningHours | null;
  public friHours: OpeningHours | null;
  public satHours: OpeningHours | null;
  public sunHours: OpeningHours | null;
  
  constructor(monHours: OpeningHours | null, tueHours: OpeningHours | null, wedHours: OpeningHours | null, thuHours: OpeningHours | null, friHours: OpeningHours | null, satHours: OpeningHours | null, sunHours: OpeningHours | null) {
      this.monHours = monHours;
      this.tueHours = tueHours;
      this.wedHours = wedHours;
      this.thuHours = thuHours;
      this.friHours = friHours;
      this.satHours = satHours;
      this.sunHours = sunHours;

  };
  public static convert(data: any): SalePathOpeningHours|null {
    if (!data)return null;
      return new SalePathOpeningHours(
          OpeningHours.import(data.monday),
          OpeningHours.import(data.tuesday),
          OpeningHours.import(data.wednesday),
          OpeningHours.import(data.thursday),
          OpeningHours.import(data.friday),
          OpeningHours.import(data.saturday),
          OpeningHours.import(data.sunday)
      );
  }

  public weekDayIndex = (date?: Date) : number => {
    const useDate = date === undefined ? new Date().getDate() : date.getDate();
    return (useDate - new Date('2024-01-01').getDate()) % 7;
    // 0=mon, 6=sun
  }

  public isOpen = () : boolean => {
    const now: Date = new Date();
    //const dayIndex: number = this.weekDayIndex();
    const dayIndex: number = now.getDay();

    var ohCurr:OpeningHours|null = null;
    var ohPrev:OpeningHours|null = null;
    switch (dayIndex){
      case 1: {
        ohCurr = this.monHours;
        ohPrev = this.sunHours;
        break;
      }
      case 2: {
        ohCurr = this.tueHours;
        ohPrev = this.monHours;
        break;
      }
      case 3: {
        ohCurr = this.wedHours;
        ohPrev = this.tueHours;
        break;
      }
      case 4: {
        ohCurr = this.thuHours;
        ohPrev = this.wedHours;
        break;
      }
      case 5: {
        ohCurr = this.friHours;
        ohPrev = this.thuHours;
        break;
      }
      case 6: {
        ohCurr = this.satHours;
        ohPrev = this.friHours;
        break;
      }
      case 0: {
        ohCurr = this.sunHours;
        ohPrev = this.satHours;
        break;
      }
      default: { 
        break; 
     }
    }
    if (ohCurr==null)return true;
    const minutes:number = (now.getHours()*60)+now.getMinutes();

    if (ohPrev && ohPrev.isOvernight()){
      if (minutes<ohPrev.to.getMinutes())return true;
    }

    return ohCurr.from.getMinutes() <= minutes && ohCurr.to.getMinutes() > minutes;
  }
}
