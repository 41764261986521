import { FC, useEffect, useState } from 'react';
import ProductOptionDialog from "../Option/ProductOptionDialog";
import OptionUnits from "../Option/OptionUnits";
import styles from './styles.module.scss';
import EditItem from '../../../models/EditItem';
import { useTranslation } from "react-i18next";


interface Props {
  item: EditItem;
}

const SalePathProductMainOption: FC<Props> = ({item}) => {
  const {t} = useTranslation();
  const [optionDialogOpen, setOptionDialogOpen] = useState<boolean>(false);
  const imageSource: string = item.salePath.imagePath;
  const [label, setLabel] = useState<string>('');

  const updateItem = () => {
    if (item.salePath.chainFreeUnits === 1) {
      const hasSaleUnitOne: boolean = item.children.some(child => child.quantity === 1);
      if (!hasSaleUnitOne && item.children.length > 0) {
        if (item.salePath.isOptional){
          setLabel(item.salePath.name);
        } else {
          item.children[0].quantity = 1;
          setLabel(item.children[0].salePath.name);
        }
      } else {
        const childWithSaleUnitOne: EditItem | undefined = item.children.find(child => child.quantity === 1);
        if (childWithSaleUnitOne) {
          setLabel(childWithSaleUnitOne.salePath.name);
        } 
      }
    } else {
      setLabel(item.salePath.name);
      //console.log('opt3');
    }

  }
  useEffect((): void => {
    updateItem();
  });

  const openProductOptionDialog = (): void => {
    if (!item.salePath.isEditable()) return;
    setOptionDialogOpen(true);
  }

  const closeProductOptionDialog = (): void => {
    setOptionDialogOpen(false);
  }

  const handleExtraSaleUnits = (item: EditItem, quantity: number): void => {
    item.quantity = quantity;
  }

  const renderExtraSale = () => {
    return item.children.filter(c => c.salePath.isExtraSale).map((subItem: EditItem, index: number) => {
      return (
        <OptionUnits
          key={index}
          label={subItem.salePath.name}
          onChanged={(units: number): void => handleExtraSaleUnits(subItem, units)}
          units={subItem.quantity}
        />
      )
    });
  }

  return (
    <div className={`container ${styles.card}`}>
      <div className={styles.image} onClick={openProductOptionDialog}>
        {
          imageSource &&
          <img src={imageSource} alt={imageSource}/>
        }
      </div>
      
      <div className={styles.content}>
        <div>
          <span>{label}</span>
          {item.salePath.hasExtraSale() && renderExtraSale()}
        </div>
        {item.salePath.isEditable() && 
          <button className='button' onClick={openProductOptionDialog}>
            {item.salePath.chainFreeUnits === 1 ? t('button.change') : t('button.edit')}
          </button>
        }
      </div>

      <ProductOptionDialog
        item={item}
        open={optionDialogOpen}
        onClose={closeProductOptionDialog}
      />

    </div>
  );
};

export default SalePathProductMainOption;
