import { FC, useEffect, useState } from 'react';
import SalePath from '../../../../models/SalePath';
import styles from './styles.module.scss';
import OptionSwitch from "../OptionSwitch";

interface Props {
  label: string,
  showLabel?: boolean,
  selected?: SalePath[];
  choices: SalePath[];
  freeCount?: number;
  maxCount?: number;
  onChanged?: (selected: SalePath | null, count: number, isFreeOfCharge: boolean) => void;
  onSelectedChanged?: (selected: SalePath[]) => void;
}

const OptionSelectCount: FC<Props> = ({label, showLabel = true, selected = [], choices, freeCount = 0, maxCount = 9, onChanged, onSelectedChanged}) => {

  const [ SelectionArray ] = useState<SalePath[]>(selected);
  const [ needToRefresh, refresh ] = useState<boolean>(false);

  useEffect((): void => {
    refresh(false);
  }, [needToRefresh]);

  const counterChanged = (source: SalePath, units: number): void => {
    const index: number = SelectionArray.indexOf(source, 0);
    const isSelected: boolean = index<0;
    if (isSelected)
      SelectionArray.push(source);
    else
      SelectionArray.splice(index,1);
    const isExtra: boolean = SelectionArray.indexOf(source,0)>=freeCount;
    if (onChanged) onChanged(source, units, !isExtra);
    if (onSelectedChanged) onSelectedChanged(SelectionArray);
    
    refresh(true);
  }

  const isPriced = (salePath: SalePath): boolean => {
    if (freeCount<1)return true;
    const index: number = SelectionArray.indexOf(salePath, 0);
    if (index<0){
      if (SelectionArray.length>=freeCount) return true;
    } else {
      if (index>=freeCount)return true;
    }
    return false;
  };
  
  const renderChoices = () => {
    return choices.map((node: SalePath, index: number) => {
      return (
        <div key={index}>
          <OptionSwitch
            key={index}
            showPrice={isPriced(node)}
            node={node}
            units={SelectionArray.find(n => n===node)!==undefined ? 1 : 0}
            onChanged={(units:number) => counterChanged(node, units)} />
        </div>
      )
    });
  }
  
  return (
    <div className={styles.optionSelectCount}>
      <span className={styles.name}>{label}</span>
      {renderChoices()}
    </div>
  );
};
export default OptionSelectCount;
