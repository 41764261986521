import ChildProductData from "./childProductData";

export default class ProductData {
  productCode: string;

  quantity: number;

  productInfo: string;

  salePrice: number | null;

  conseptName: string;

  salePathCode: string;

  isContent: boolean;

  childProducts: Array<ChildProductData>;

  constructor(
    productCode: string,
    quantity: number,
    productInfo: string,
    salePrice: number | null,
    conseptName: string,
    salePathCode: string,
    isContent: boolean,
    childProducts: Array<ChildProductData>,
  ) {
    this.productCode = productCode;
    this.quantity = quantity;
    this.productInfo = productInfo;
    this.salePrice = salePrice;
    this.conseptName = conseptName;
    this.salePathCode = salePathCode;
    this.isContent = isContent;
    this.childProducts = childProducts;
  }

}
