import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss'
import { useTranslation } from "react-i18next";

const waitTime: number = 10;
const KioskFront: FC = () => {

  const [counter, setCounter] = useState<number>(waitTime);
  const [language, setLanguage] = useState<string>('fi');
  const {i18n} = useTranslation();

  const video = document.querySelector("video");

  useEffect(() => {
    const to:NodeJS.Timeout | null = waitTime > 0 ?setTimeout(()=>{
      if(counter>0)setCounter((c)=>c-1);
      if (waitTime>0&&counter===0){
        setCounter(waitTime);
        switch(language){
          case 'en':{
            setLanguage('fi');
            break;
          }
          case 'se':{
            setLanguage('en');
            break;
          }
          default:{
            setLanguage('se');
            break;
          }
        }
      }
      },1000):null;
      if (video?.paused)video?.play();
    return () => {
      if (to) clearTimeout(to);
    };
  },[counter, language, video]);

  const getText = () : string => {
    return i18n.getFixedT(language)('start');
  }


  const getVideoUrl = () : string => {
    return `${process.env.REACT_APP_BASKET_IMAGE_URI}/BasketKiosk/IdleMedia.mp4`;
  };

  return (
    <div className={styles.kioskFrontBackground}>
      <div className={styles.kioskFront}>
        <video width="1080" height="1920" autoPlay loop muted>
          <source src={getVideoUrl()} type="video/mp4"/>
        </video>
      </div>
      {getText().length>0 &&(
        <div className={styles.actions}>
          {getText()}
        </div>
      )}
    </div>
  );
};

export default KioskFront;
