import './App.scss';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { KioskContext } from '../context/KioskContext';
import Browse from './pages/Browse';
import Settings from './pages/settings';
import { isLocalSettingsDefined } from '../helpers/localStorageHelper';
import Config from '../hooks/Config';
import { useTranslation } from "react-i18next";
import ErrorBoundary from './components/ErrorBoundary';
import { handleApplicationError } from '../helpers/loggerApi';
import useUpdate from '../hooks/useUpdate';

const App: FC = () => {
  const {state} = useContext(KioskContext);
  const {version, debugMode } = Config();
  const {i18n} = useTranslation();
  const [ updateInitialized, setUpdateInitialized] = useState<boolean>(false);
  const { initializeUpdate } = useUpdate();

  useEffect((): void => {
    console.log('React', React.version);
    i18n.changeLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);
  }, [i18n]);
  useEffect((): void => {
    console.log('Version', version );
  }, [version]);
  useEffect((): void => {
    if (debugMode){
      console.log('DEBUG ON');
    }
  }, [debugMode]);
  useEffect((): void => {
    if (!updateInitialized){
      setUpdateInitialized(true);
      initializeUpdate();
    }
  }, [initializeUpdate, updateInitialized]);

  const onError = async (error:Error, info:React.ErrorInfo) => {
    handleApplicationError('Root', state.cart?.id, undefined, error);
  }

  return (
    <div className="App">
      <Router>
        <ErrorBoundary onError={onError}>
          <Routes>
            <Route path="/" element={ isLocalSettingsDefined() ? <Browse/> : <Settings/>}/> 
            <Route path="/settings" element={<Settings/>}/>
            <Route path="*" element={<>404</>}/>
          </Routes>
        </ErrorBoundary>
      </Router>
    </div>
  );
}

export default App;
