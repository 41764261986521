import { FC } from 'react';
import SalePath from '../../../models/SalePath';
import styles from './styles.module.scss';

interface SalePathNodeProps {
  node: SalePath
}

const ConceptHeader: FC<SalePathNodeProps> = ({node}) => {

  const imageSource: string = node.imagePath;

  return (
    <div className={styles.conceptHeader}>
      <div className={styles.image}>
        {imageSource &&
          <img
            src={imageSource}
            alt=""
            id={node.id.toString()}
          />
        }
      </div>
    </div>
  );
};

export default ConceptHeader;
