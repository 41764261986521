interface ApiConfig {
  apiUrl: string;
  apiKey: string;
  measure: boolean;
}

const apiUrl: string = process.env.REACT_APP_BASKET_URI || '';
const apiKey: string = process.env.REACT_APP_BASKET_KEY || '';

const measure:boolean = Boolean(process.env.REACT_APP_APIMEASURE || 'true');

export const getConfig = (): ApiConfig => {
    return {apiUrl: apiUrl, apiKey: apiKey, measure: measure};
};