import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface Props {
  label: string,
  units: number;
  unitsMin?: number;
  unitsMax?: number;
  unitsFree?: number;
  canIncrease?: boolean;
  onChanged?: (units: number) => void;
}

const OptionUnits: FC<Props> = ({label, units, unitsMin = 0, unitsMax = 9, unitsFree = 0, canIncrease = true, onChanged}) => {
  const [value, setValue] = useState<number>(units);
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);

  useEffect((): void => {
    if (units > 0) {
      setValue(units);
      setIsSwitchOn(true);
    }
  }, [units]);

  const valueChanged = (newValue: number): void => {
    setValue(newValue);
    if (onChanged) onChanged(newValue);
  }

  const toggleSwitch = (): void => {
    let newValue: number = 0;
    setIsSwitchOn(prevState => !prevState);
    if (!isSwitchOn) newValue = value + 1;
    else {
      if (newValue < unitsMin) return;
      newValue = value - 1;
    }
    valueChanged(newValue);
  }

  return (
    <div className={styles.OptionUnits}>
      <div className={`switch ${isSwitchOn ? 'on' : 'off'}`} onClick={toggleSwitch}>
        <div className="switchBtn"/>
      </div>
      <span onClick={toggleSwitch}>{label}</span>
    </div>
  );
};
export default OptionUnits;
