import { FC, ReactNode, useState } from 'react';
import styles from './styles.module.scss';
import { Box } from '@mui/material';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useTranslation } from 'react-i18next';

interface Props {
  value: number|undefined;
  maxValue?: number;
  enabled?: boolean;
  showValue?: boolean;
  onValueChange?: (value: number|undefined) => void;
}

const NumericEntry: FC<Props> = ({value, maxValue=999, enabled=true, showValue = false, onValueChange}) => {

  const [ numericValue, setNumericValue ] = useState<number|undefined>(value);
  // const refThis = useRef<HTMLDivElement|null>(null);
  const {t} = useTranslation();

  const isNumber = (value:string) : boolean => {
    return value.match('[0-9]') != null;
  }
  const onButtonPress = (value:string) => {
    var numeric:number|undefined = numericValue;
    
    if (isNumber(value)){
      numeric = (numeric||0)*10+Number.parseInt(value);
    } else {
      switch(value){
        case 'CLR':{
          numeric = undefined;
          break;
        }
        case 'DEL':{
          numeric = Math.floor((numeric||0)/10);
          if (numeric===0)numeric=undefined;
          break;
        }
      }
    }
    if (numeric&&numeric > maxValue)return;
    setNumericValue(numeric);
    if (onValueChange)onValueChange(numeric);
  }
  
  
  const renderButton = (value:string) : ReactNode => {
    var node:ReactNode|undefined;

    if (isNumber(value)){
      node = (<div className={styles.buttonText}>{value}</div>);
    }
    switch(value){
      case 'CLR':{
        node = (<ClearOutlinedIcon className={styles.buttonIcon}/>);
        //node = (<div>Tyhjennä</div>);
        break;
      }
      case 'DEL':{
        node = (<BackspaceOutlinedIcon className={styles.buttonIcon}/>);
        break;
      }
    }
    if (node){
      return (
        <div className={styles.control} onClick={() => {onButtonPress(value)}}>
          <div className={styles.button}>
            {node}
          </div>
        </div>
      );
    }
    return (<></>);
  }
  
  return (
    <div className={styles.numericEntry}>
      {showValue && (
        <Box className={styles.value} component="section">
          <div className={value ? styles.labelHasContent: styles.labelNoContent}>
            {value ? value : t('table-novalue')}
          </div>
        </Box>
      )}
      <div className={styles.controls}>
      <table>
        <colgroup>
        <col className={styles.column}/>
        <col className={styles.column}/>
        <col className={styles.column}/>
        </colgroup>
        <tbody>
          <tr><td>{renderButton('1')}</td>
          <td>{renderButton('2')}</td>
          <td>{renderButton('3')}</td></tr>
          <tr><td>{renderButton('4')}</td>
          <td>{renderButton('5')}</td>
          <td>{renderButton('6')}</td></tr>
          <tr><td>{renderButton('7')}</td>
          <td>{renderButton('8')}</td>
          <td>{renderButton('9')}</td></tr>
          <tr><td>{renderButton('CLR')}</td>
          <td>{renderButton('0')}</td>
          <td>{renderButton('DEL')}</td></tr>
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default NumericEntry;
