import SalePath from '../models/SalePath';
import { getConfig } from './apiConfig';
import { handleApiError } from './loggerApi';

const apiUrl: string = getConfig().apiUrl;
const measure:boolean = getConfig().measure;

export const getSalePaths = async (shopCode: string, token: string, language?: string): Promise<Array<SalePath>> => {
  try {
    if (measure)console.time('getSalePaths');
    const response = await fetch(`${apiUrl}/search/${shopCode}/salepath${language!==undefined?`?language=${language}`:''}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (measure)console.timeEnd('getSalePaths');
    if (response.ok) {
      return SalePath.convertAll(await response.json());
    } else {
      handleApiError('getSalePaths', response);
      return Promise.reject(await response.json());
    }
  } catch (error) {
    handleApiError('getSalePaths', error);
    throw error;
  }
};
