import { useContext, useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { KioskContext } from "../../../../context/KioskContext";
import { KioskActionsEnum } from "../../../../context/KioskContext/types";
import { useTranslation } from "react-i18next";
import PaycoPayment from '../../../../hooks/PaycoPayment'
import OrderPaymentErrorDialog from '../OrderPaymentErrorDialog';

export const OrderPayment = () => {
  const {t} = useTranslation();
  const {state, dispatch} = useContext(KioskContext);
  const [executingPayment, setExecutingPayment] = useState<boolean>(false);
  const [cancellingPayment, setCancellingPayment] = useState<boolean>(false);
  const [paymentScreenMessage, setPaymentScreenMessage] = useState<string>('')
  const {paycoState, paycoExecute, paycoCancel, paycoReset} = PaycoPayment();
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const [errorDialogShown, showErrorDialog] = useState<boolean>(false);

  useEffect(() => {
    if (state.paymentPending&&!executingPayment){
      setPaymentScreenMessage(t('payment.begin'));
      dispatch({type: KioskActionsEnum.PAYMENT_INPROCESS})
      setExecutingPayment(true);
      setCancellingPayment(false);
      setErrorOccured(false);
      paycoExecute();
      return;
    }
    
    if (paycoState.isCompleted){

      if (paycoState.isSucceeded){

          //Payment Completed
          if (state.cart?.state.toLowerCase()==='finalized'){
            console.log('Completed payment');
            dispatch({type: KioskActionsEnum.PAYMENT_SUCCESSFUL})
            dispatch({type: KioskActionsEnum.RELOAD})
          }
          else {
            dispatch({type: KioskActionsEnum.PAYMENT_FAILED})
            setPaymentScreenMessage(t('payment.exception-abort'));
            setErrorOccured(true);
            showErrorDialog(true);
          }
        } else {

          // Error in Payment
          dispatch({type: KioskActionsEnum.PAYMENT_FAILED})
          setErrorOccured(true);
          if (paycoState.exception)
            setPaymentScreenMessage(t('payment.exception-abort'));
          else
            setPaymentScreenMessage(t('payment.error'));
          showErrorDialog(true);
        }
        setExecutingPayment(false);
        paycoReset();
    }
  }, [dispatch, paycoExecute, paycoReset ,paycoState.exception, paycoState.isCompleted, paycoState.isSucceeded, state.cart?.state, state.paymentPending, t, executingPayment]);

  useEffect(() => {
    if (paycoState.message!=='' && paymentScreenMessage!== paycoState.message) setPaymentScreenMessage(paycoState.message);
  }, [paycoState, paymentScreenMessage]);  

  const cancelPayment = () => {
    setCancellingPayment(true);
    paycoCancel();
  }

  const retry = () => {
    setPaymentScreenMessage('');
    setErrorOccured(false);
    showErrorDialog(false);
    dispatch({type: KioskActionsEnum.START_PAYMENT})
  }

  const jump = () => {
    dispatch({type: KioskActionsEnum.SELECT_NEXT_STEP, step: 4})
    setErrorOccured(false);
    showErrorDialog(false);
  }

  return (
    <>
      <div className={styles.orderPayment}>
        <h1>{t('pay-the-order')}</h1>
        <div>
          {!errorOccured && (
            <>
            <div className={styles.paymentInfo}>
              <div className={styles.paymentHeader}>
                {paymentScreenMessage}
              </div>
              <div className={styles.paymentText}>
                {t('pay-info')}
              </div>
              <div className={styles.buttons}>
                <button className={`button ${(!executingPayment || cancellingPayment) ? styles.disabledBtn : ''}`} onClick={cancelPayment} disabled={!executingPayment}>{t('button.cancel-payment')}</button>
              </div>
            </div>
            <div className={styles.triangle}/>
            </>)
          }
        </div>
      </div>
      <OrderPaymentErrorDialog open={errorDialogShown} onReturn={jump} onTryAgain={retry}/>
    </>
  );
};
