import { FC, useState, useContext } from 'react';
import SideMenuButton, { IconType } from './SideMenuButton';
import { KioskContext } from '../../../context/KioskContext';
import { BasketEnum, KioskActionsEnum, OrderStepsEnum } from "../../../context/KioskContext/types";
import styles from './styles.module.scss'
import { useTranslation } from "react-i18next";
import { formatMoney } from "../../../helpers/formatHelper";
import ConfirmDialog from '../ConfirmDialog';
import LanguageDialog from '../LanguageDialog';
import AlarmIcon from '@mui/icons-material/ErrorOutline';

const SideMenu: FC = () => {
  const {state, dispatch} = useContext(KioskContext);
  const [languageDialogShow, setLanguageDialogShow] = useState<boolean>(false);
  const [confirmResetShow, setConfirmResetShow] = useState<boolean>(false);
  const {t} = useTranslation();
  const { i18n } = useTranslation('home');

  const onRootClick = (): void => {
    dispatch({type: KioskActionsEnum.SELECT_ROOT});
    dispatch({type: KioskActionsEnum.SELECT_STEP, step: 0});
  }

  const onBackClick = (): void => {
    if (state.orderSteps > 0) {
      dispatch({type: KioskActionsEnum.SELECT_PREVIOUS_STEP});
    } else
      dispatch({type: KioskActionsEnum.SELECT_PARENT});
  }

  const onBasketClick = (): void => {
    dispatch({type: KioskActionsEnum.SHOW_BASKET});
  }

  const onEmptyBasketClick = (): void => {
    if (paymentInProgress===true)return;
    if ((state.cart?.products.length||0) > 0){
      setConfirmResetShow(true);
    } else {
      onRootClick();
    }
  }

  const onConfirmReset = (result: boolean): void => {
    setConfirmResetShow(false);
    if (result){
      dispatch({type: BasketEnum.REMOVE_ORDER});
      dispatch({type: KioskActionsEnum.SELECT_ROOT});
      dispatch({type: KioskActionsEnum.SELECT_STEP, step: 0});
    }
  }

  const onLanguageDialogClose = (result: string): void => {
    setLanguageDialogShow(false);
  }

  const hideStep: boolean = state.orderSteps > 4;

  const receiptInProgress: boolean = (state.cart?.products.length||0) > 0;
  const paymentInProgress: boolean = (state.paymentInProcess!==null&&state.paymentInProcess===true);

  return (
    <>
      <div className={styles.sideBarMenu}>
        <div className={`${hideStep ? styles.hideStep : ''}`}>
          {state.orderSteps  < 5 &&
            <SideMenuButton 
              disabled={state.salePath == null} 
              label={t('button.back')} 
              icon={IconType.Back} 
              onClick={onBackClick}
            />
          }
        </div>
        <div className={`${hideStep ? styles.hideStep : ''}`}>
        {state.orderSteps < 5 &&
            <SideMenuButton
              label={`${t('button.order')} ${state.cart ? `|${formatMoney(state.cart.receiptTotal)} €` : ''}`}
              icon={IconType.Basket}
              disabled={!(receiptInProgress)}
              onClick={onBasketClick}
            />
          }
        </div>
        <div className={`${hideStep ? styles.hideStep : ''}`}>
        {state.orderSteps < 5 &&
            <SideMenuButton 
              disabled={state.salePath == null} 
              label={t('button.home')} 
              icon={IconType.Home} 
              onClick={onRootClick}
            />
          }
        </div>
        <div className={`${hideStep ? styles.hideStep : ''}`}>
        {state.orderSteps < 5 &&
            <SideMenuButton 
              disabled={state.salePath == null && state.cart == null} 
              label={`${t('button.language')}|${i18n.language.toUpperCase()}`} 
              icon={IconType.Language} 
              onClick={() => {setLanguageDialogShow(true)}}
            />
          }
        </div>
        <div 
          className={`
          ${state.orderSteps === OrderStepsEnum.STEP_SIX ? styles.hideStep : ''} 
          ${state.orderSteps === OrderStepsEnum.STEP_FIVE ? styles.changeBorderStep : ''}
              `}
        >
          {state.orderSteps !== OrderStepsEnum.STEP_SIX &&
            <SideMenuButton
              label={t('button.return-to-start')}
              icon={IconType.Clear}
              disabled={ !(receiptInProgress||paymentInProgress||state.salePath!==null)}
              onClick={onEmptyBasketClick}
            />
          }
        </div>
      </div>
      <LanguageDialog open={languageDialogShow} onClose={(r) => {onLanguageDialogClose(r)}} />
      <ConfirmDialog
        icon={<AlarmIcon/>}
        title={t('cancelOrder')}
        content={t('cancelOrderContent')}
        confirmText={t('cancelOrder')}
        cancelText={t('button.cancel')}
        open={confirmResetShow} onClose={(r) => {onConfirmReset(r)}} />
    </>
  );
};

export default SideMenu;
