import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
  fallbackRender?: (error: Error, errorInfo: ErrorInfo) => JSX.Element;
  children?: ReactNode;
}

interface State {
  error: Error|null;
  errorInfo: ErrorInfo|null;
}

class ErrorBoundary extends Component<Props, State> {

  public state: State = { error: null, errorInfo: null };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({error:error, errorInfo:errorInfo});
    if (this.props.onError){
      try {
        this.props.onError(error, errorInfo);
      } catch {}
    }
  }

  public render() {
    if (this.state.error && this.state.errorInfo && this.props.fallbackRender){
      return this.props.fallbackRender(this.state.error, this.state.errorInfo);
    }
    return this.props.children;
  }
}

export default ErrorBoundary;