import SalePath from "./SalePath";

export default class SalePathData {

  rootItems: Array<SalePath>;

  constructor(
    rootItems: Array<SalePath>
    ) {
      this.rootItems = rootItems;
    }

  public findBySalePathCode = (salePathCode: string, item?: SalePath | null) : SalePath | null => {
    if (item != null){
      if (item.code === salePathCode){
        return item;
      }
      for (let i = 0; i < item.children.length; i++) {
        const result = this.findBySalePathCode(salePathCode, item.children[i])
        if (result){
          return result;
        }
      }
      return null;
    }
    else
    {
      for (let i = 0; i < this.rootItems.length; i++) {
        const result = this.findBySalePathCode(salePathCode, this.rootItems[i])
        if (result){
          return result;
        }
      }
      return null;
    }
  }


  public checkVisibility = () : boolean => {
    var changed:boolean = false;
    this.rootItems.forEach(d => {
      const wasVisible:boolean = d.isVisible;
      d.checkVisibility();
      if (!changed && wasVisible !== d.isVisible)
        changed = true;
    });
    return changed;
  }
  
  public isClosed = () : boolean => {
    return (this.rootItems.find(r => r.isVisible) === undefined);
  }
}
