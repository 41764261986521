import IDCodeName from "./IDCodeName";
import SalePathOpeningHours from "./SalePathOpeningHours";

export default class SalePath extends IDCodeName {

  nodeType: string;
  infoText: string;
  description: string;
  parentID: number | null;
  parent: SalePath | null = null;
  productID: number | null;
  productCode: string;
  orderNumber: number;
  isOptional: boolean;
  imagePath: string;
  children: SalePath[];
  isChainProductDefined: boolean;
  chainUnits: number | null;
  chainFreeUnits: number | null;
  chainIsFreeOfCharge: boolean | null;
  isContent: boolean;
  salePrice: number;
  conseptName: string = "";
  specialDiets: string[];
  allergens: string[];
  openingHours: SalePathOpeningHours|null;
  isVisible: boolean = true;

  constructor(
    nodeType: string,
    id: number,
    code: string,
    name: string,
    infoText: string,
    description: string,
    parentID: number | null,
    productID: number | null,
    productCode: string,
    orderNumber: number,
    isOptional: boolean,
    imagePath: string,
    chainUnits: number | null,
    chainFreeUnits: number | null,
    chainIsFreeOfCharge: boolean | null,
    isContent: boolean,
    salePrice: number,
    specialDiets: string[],
    allergens: string[],
    openingHours: SalePathOpeningHours|null,
    ) {
    super(id, code, name);
    this.nodeType = nodeType;
    this.infoText = infoText;
    this.description = description;
    this.parentID = parentID;
    this.productID = productID;
    this.productCode = productCode;
    this.orderNumber = orderNumber;
    this.isOptional = isOptional;
    this.imagePath = imagePath;
    this.children = [];
    this.isChainProductDefined = chainUnits !== null;
    this.chainUnits = chainUnits == null ? null : chainUnits === 0 ? 1 : chainUnits;
    this.chainFreeUnits = chainFreeUnits;
    this.chainIsFreeOfCharge = chainIsFreeOfCharge;
    this.isContent = isContent;
    this.salePrice = salePrice;
    this.specialDiets = specialDiets;
    this.allergens = allergens;
    this.openingHours = openingHours;
    this.checkVisibility();
  }

  static convertAll = (items: any[]): SalePath[] => {
    const rootItems: SalePath[] = items.filter(i => i.parentID === null).map((m) => SalePath.convert(m));
    const setChildren = (parent: SalePath, conseptName: string): void => {
      parent.children = items.filter(i => i.parentID === parent.id).map((m) => SalePath.convert(m));
      parent.children.forEach(i => {
        i.parent = parent;
        i.conseptName = conseptName;
        setChildren(i, conseptName);
      });
    }
    rootItems.forEach(i => {
      setChildren(i, i.name);
    });
    if (rootItems.length === 1) return rootItems[0].children;
    return rootItems;
  };  

  static convert = (item: any): SalePath => {
    return new SalePath(
      item.nodeType,
      item.salePathID,
      item.salePathCode,
      item.salePathName,
      item.infoText,
      item.description,
      item.parentID,
      item.productID,
      item.productCode,
      item.orderNumber,
      item.isOptional,
      item.imagePath,
      item.chainUnits,
      item.chainFreeUnits,
      item.chainIsFreeOfCharge,
      item.isContent,
      item.salePrice,
      item.specialDiets,
      item.allergens,
      SalePathOpeningHours.convert(item.openHours),
    );
  };  

  static findByID = (id: number, collection: SalePath[]): SalePath | null=> {
    collection.forEach((item: SalePath) => {
      if (item.id === id)
      return item;
      if (item.hasChildren()){
        const subItem: SalePath | null = this.findByID(id, item.children);
        if (subItem !== null)return subItem;
      }
    });
    return null;
  };  

  public hasProduct = () : boolean => {
    return this.productCode !== null && this.productCode.length > 0;
  }

  public hasParentProduct = () : boolean => {
    if (this.parent == null)return false;
    return this.parent.hasProduct();
  }

  public hasChildren = () : boolean => {
    return this.children !== null && this.children.length > 0;
  }

  public hasOptions = () : boolean => {
    return this.hasChildren();
  }

  public hasChoices = () : boolean => {
    return this.hasParentProduct() && this.isChainProductDefined && this.hasChildren() && (this.chainFreeUnits !== null && this.chainFreeUnits > 0);
  }
  
  public IsSelectOne = () : boolean => {
    return this.hasChoices() && (this.chainFreeUnits !== null && this.chainFreeUnits === 1);
  }
  
  public IsSelectMany = () : boolean => {
    return this.hasChoices() && (this.chainFreeUnits !== null && this.chainFreeUnits > 1);
  }

  public isMainProduct = () : boolean => {
    return this.hasProduct() && !this.hasParentProduct();
  }

  public isChoice = () : boolean => {
    return this.hasParentProduct() && this.isChainProductDefined && !this.hasChildren();
  }

  public isExtraSale = () : boolean => {
    return !this.isMainProduct() && !this.isChainProductDefined;
  }
  
  public isEditable = () : boolean => {
    return this.hasChildren() && this.children.filter(c => c.isOptional && c.chainUnits !== null).length > 0;
  }
  
  public hasExtraSale = () : boolean => {
    return this.hasChildren() && this.children.filter(c => c.isOptional && !c.isChainProductDefined).length > 0;
  }
  
  public checkVisibility = () => {
    if (this.parentID)return;
    if (this.openingHours){
      this.isVisible = this.openingHours.isOpen();
    } else {
      this.isVisible = true;
    }
  }

  public getRoot = () : SalePath => {
    var parent:SalePath|null = this.parent;
    var prev:SalePath = this;
    while(parent!=null){
      prev = parent;
      parent = parent.parent;
    }
    return prev;
  }

  public toString = () : string => {
    return `SalePath (${this.id}): ${this.name}`;
  }
}
