import { FC, ReactNode } from 'react';
import SalePath from '../../../models/SalePath';
import OptionSelect from "../Option/OptionSelect";
import OptionSelectCount from "../Option/OptionSelectCount";
import OptionSwitch from "../Option/OptionSwitch";
import styles from './styles.module.scss'
import EditItem from '../../../models/EditItem';
//import Config from '../../../hooks/Config';

interface Props {
  item: EditItem;
  onClose: (bool: boolean) => void;
}

const debugMode:boolean = false;
const SalePathProductOption: FC<Props> = ({item, onClose}) => {
  
  //const {debugMode} = Config();

  const handleSaleUnits = (item: EditItem, quantity: number): void => {
    if (debugMode)console.log('SalePathProductOption', 'handleSaleUnits', item.salePath.name, item.quantity, '->', quantity);
    item.quantity = quantity;
  }
  
  const getSelected = (item: EditItem) : SalePath | null => {
    const selected: EditItem | undefined = item.children.find(i => i.quantity === 1);
    const isOptional:boolean = item.salePath.isOptional;
    if (selected !== undefined) return selected.salePath;
    else {
      if (isOptional){
        return null;
      } else {
        handleSelection(item, item.children[0].salePath);
        return item.children[0].salePath;
      }
    }
  }

  const handleSelection = (item: EditItem, selected: SalePath | null): void => {
    item.children.forEach(i => {
      const prevValue:number = i.quantity;
      i.quantity = (selected !== null && i.salePath.code === selected.code) ? 1 : 0;
      if (debugMode)console.log('SalePathProductOption', 'handleSelection', item.salePath.name, i.salePath.name, prevValue, '->', i.quantity);
    });
  }

  const handleSelectedMany = (item: EditItem, selected: SalePath[]): void => {
    item.children.forEach((i: EditItem) => {
      i.children.forEach((choice: EditItem) => {
        const index: number = selected.indexOf(choice.salePath, 0);
        const prevValueQ:number = choice.quantity;
        const prevValueF:boolean = choice.forcedPricing;
        if (index>=0){
          choice.quantity = 1;
          choice.forcedPricing = ((i.salePath.chainFreeUnits || 0) <= index);
        } else {
          choice.quantity = 0;
          choice.forcedPricing = false;
        }
        if (debugMode && (prevValueQ!==choice.quantity||prevValueF!==choice.forcedPricing)){
          console.log('SalePathProductOption', 'handleSelectedMany', item.salePath.name, i.salePath.name, choice.salePath.name, prevValueQ, '->', choice.quantity, prevValueF, '->', choice.forcedPricing);
        }
      });
    });
  }

  const renderChildren = (): ReactNode | undefined => {
    if (!item.salePath.hasChildren()) 
      return <div style={{textAlign: 'left'}}>no children: {item.salePath.name}</div>;

    if (item.salePath.chainFreeUnits != null && item.salePath.chainFreeUnits > 1) {
      const choices: SalePath[] = [];
      item.children.filter(i => i.forcedPricing === false).forEach(s => { choices.push(s.salePath);})
      item.children.filter(i => i.forcedPricing === true).forEach(s => { choices.push(s.salePath);})
      return (
        <OptionSelectCount 
          label={item.salePath.name} 
          showLabel={false} 
          choices={choices} 
          freeCount={item.salePath.chainFreeUnits || 0}
        />
      );
    }

    if (item.salePath.chainFreeUnits === 1) {
      // 'Limuvalinta'
      return (
        <OptionSelect 
          label={item.salePath.name}
          selected={getSelected(item)} 
          choices={item.salePath.children} 
          isOptional={item.salePath.isOptional}
          onChanged={(selected: SalePath | null) => handleSelection(item, selected)}
        />
      );
    }

    return item.children.map((child: EditItem, iChild: number) => {

      if (!child.salePath.isChainProductDefined) {
        return (
          <OptionSwitch
            key={iChild} 
            node={child.salePath} 
            onChanged={(units: number): void => handleSaleUnits(child, units)} 
            units={child.quantity} 
            disabled={!child.salePath.isOptional} 
          />
        );
      }

      if (child.salePath.IsSelectMany()) {
        // Täytteet / tuotteen ominaisuudet
        const choices: SalePath[] = child.children.map((c) => c.salePath);

        const selectedItems: SalePath[] = [];
        child.children.filter(i => i.quantity > 0 && i.forcedPricing === false).forEach(s => { selectedItems.push(s.salePath);})
        child.children.filter(i => i.quantity > 0 && i.forcedPricing === true).forEach(s => { selectedItems.push(s.salePath);})
    
        return (
          <div key={iChild}>
            <OptionSelectCount
              selected={selectedItems}
              key={iChild}
              label={child.salePath.name} 
              showLabel={false} 
              choices={choices} 
              freeCount={child.salePath.chainFreeUnits || 0}
              onSelectedChanged={(selected) => handleSelectedMany(item, selected)}
            />
          </div>
        );
      }

      return (
        <div key={iChild}>
          <OptionSwitch
            key={iChild}
            node={child.salePath}
            onChanged={(units: number): void => handleSaleUnits(child, units)}
            units={child.quantity}
            disabled={!child.salePath.isOptional}
          />
        </div>
      );
    });
  }

  const subTitle = (): string => {
    return item.salePath.name;
  }

  return (
    <>
      <div className={styles.title}>
        <h2>{subTitle()}</h2>
      </div>
      <div className={styles.salePathProductOption}>
        <div className={styles.content}>
          {renderChildren()}
        </div>
      </div>
    </>
  );
};

export default SalePathProductOption;
