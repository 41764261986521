import SalePath from "../../models/SalePath";
import Cart from "../../models/cart";
import SalePathData from "../../models/SalePathData";
import EditData from "../../models/EditData";
import ILocalSettings from "../../models/localSettings";

export enum KioskActionsEnum {
  LOGIN_USER = 'LOGIN_USER',
  SET_SETTINGS = 'SET_SETTINGS',
  RELOAD = 'RELOAD',
  LOADED = 'LOADED',
  SELECT_ROOT = 'SELECT_ROOT',
  SELECT_PARENT = 'SELECT_PARENT',
  SHOW_BASKET = 'SHOW_BASKET',
  BASKET_ITEM_ADD = 'BASKET_ITEM_ADD',
  SELECT_NODE = 'SELECT_NODE',
  SELECT_PREVIOUS_STEP = 'SELECT_PREVIOUS_STEP',
  SELECT_NEXT_STEP = 'SELECT_NEXT_STEP',
  SELECT_STEP = 'SELECT_STEP',
  START_PAYMENT = 'START_PAYMENT',
  PAYMENT_INPROCESS = 'PAYMENT_INPROCESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  ORDER_COMPLETE = 'ORDER_COMPLETE',
  UPDATE_CART = 'UPDATE_CART',
  TIMEOUT = 'TIMEOUT',
}

export enum BasketEnum {
  HIDE_BASKET = 'HIDE_BASKET',
  REMOVE_ORDER = 'REMOVE_ORDER'
}

export type KioskReducer = (state: IKioskContext, action: any) => IKioskContext;

export enum OrderStepsEnum {
  STEP_ZERO = 0,
  STEP_ONE = 1,
  STEP_TWO = 2,
  STEP_THREE = 3,
  STEP_FOUR = 4,
  STEP_FIVE = 5,
  STEP_SIX = 6
}

export interface IKioskContext {
  settings: ILocalSettings | null;
  operator: string | null;
  isBrowsing: boolean,
  isDataLoaded: boolean,
  loadedAt: Date | null,
  orderSteps: OrderStepsEnum,
  isConfirmingBasket: boolean,
  salePathData: SalePathData,
  salePath: SalePath | null,
  editData: EditData | null,
  cart: Cart | null,
  paymentInProcess: boolean | null,
  paymentPending: boolean | null,
}