import { FC, useContext } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import { useTranslation } from "react-i18next";
import Product from '../../../models/product';
import { formatMoney } from "../../../helpers/formatHelper";
import styles from './styles.module.scss'
import SalePath from '../../../models/SalePath';

interface DisplayProductProps {
  product: Product
  onModify?: (product: Product) => void;
  onRemove?: (product: Product) => void;
}

const DisplayProduct: FC<DisplayProductProps> = ({product, onModify, onRemove}) => {

  const {t} = useTranslation();
  const { state } = useContext(KioskContext);

  const renderChildrenLines = (item: Product, depth: number) : string[] => {
    const lines:string[] = [];
    if (item === null || item.products.length === 0) return lines;
    item.products?.forEach(i => {
      const showLine: boolean = (i.isContent && i.quantity === 0) || (!i.isContent && i.quantity !== 0);
      if (showLine){
        lines.push(`${i.quantity===0?'Ei ':''}${i.productName}`);
      }
      const child:string[] = renderChildrenLines(i, depth+1);
      child?.forEach(c => lines.push(c));
    });
    return lines;
  }

  const doModify = () => {
    if (onModify)onModify(product);
  }
  const doRemove = () => {
    if (onRemove)onRemove(product);
  }

  const salePath: SalePath|null = state.salePathData.findBySalePathCode(product.salePathCode);

  return (
    <div key={product.rowId} className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.leftContent}>
          <div className={styles.image}>
            {salePath && <img src={salePath.imagePath} alt={salePath.imagePath}/>}
          </div>
          <div className={styles.orderButtons}>
            {onModify!=null && (<button className={`button`} onClick={doModify}>{t('button.edit')}</button>)}
            {onRemove!=null && (<button className={`button`} onClick={doRemove}>{t('button.remove')}</button>)}
          </div>
        </div>

        <div className={styles.textContent}>
          <div className={styles.titleRow}>
            <div className={styles.title}>
              <span>{product.productName}</span>
            </div>
            <div className={styles.units}>
              {product.quantity} {t('pcs')}
            </div>
            <div className={styles.amount}>
              {formatMoney(Product.cumulatedTotalAmount(product))} €
            </div>
          </div>
          <div className={styles.children}>
            {renderChildrenLines(product, 1).map((l, i) => (<div key={i}>{l}</div>))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayProduct;
