import { initialLocalSettings } from '../models/localSettings';
import ILocalSettings from '../models/localSettings';

// LocalSettings
const keyLocalSettings = 'posmanagerBasketKioskSettings';

export const isLocalSettingsDefined = (): boolean => {
  const settings = getLocalSettings();
  return (settings.shopCode.length>0&&settings.posCode.length>0);
};

export const getLocalSettings = (): ILocalSettings => {
  const json: string | null = localStorage.getItem(keyLocalSettings);
  if (json === null || json.length === 0){
    localStorage.setItem(keyLocalSettings,'');
    return initialLocalSettings;
  }
  const result:ILocalSettings = JSON.parse(json);
  if (!result.timerFront) result.timerFront = initialLocalSettings.timerFront;
  if (!result.timerInactivity) result.timerFront = initialLocalSettings.timerInactivity;
  if (!result.timerButton) result.timerFront = initialLocalSettings.timerButton;
  if (!result.timerReload) result.timerFront = initialLocalSettings.timerReload;
  return result;
};

export const removeLocalSettings = (): void => {
  localStorage.removeItem(keyLocalSettings);
};

export const setLocalSettings = (localSettings: ILocalSettings): boolean => {
  try {
    localStorage.setItem( keyLocalSettings, localSettings ? JSON.stringify(localSettings):'');
    return true;
  } catch (e) {
    return false;
  }
};
