const apiUrl: string = process.env.REACT_APP_UPPROXY_URI || '';
//const apiUrl:string = 'http://127.0.01:24000';

export const postReceiptLayout = async ( layout:string ) : Promise<Error|boolean> => {
  try {
    await fetch(`${apiUrl}/api/printreceipt`, {
      method: 'post',
      mode: 'no-cors', 
      headers: {
        'Content-Type': 'application/xml',
      },
      body: layout,
    });
  } catch (error) {
    //console.log('', 'failed to print receipt', error);
    if (error instanceof Error) return error;
    return false;
  }
  return true;
};
