import { useContext, useState } from 'react';
import styles from './styles.module.scss';
import { KioskContext } from "../../../../context/KioskContext";
import { KioskActionsEnum, OrderStepsEnum } from "../../../../context/KioskContext/types";
import { useTranslation } from "react-i18next";
import { DeliveryMethodEnum, setDeliveryMethod } from '../../../../helpers/basketApi';

export const SelectDeliveryMethod = () => {
  const {t} = useTranslation();
  const {state, dispatch} = useContext(KioskContext)

  const isMethodInUse = (method: DeliveryMethodEnum) : boolean => {
    if (state.cart?.posParameters){
      const prm = state.cart.posParameters.find(p => p.parameterCode.toLowerCase()===`basketdelivery-${method.toLowerCase()}-inuse`);
      if (prm)return (prm.parameterValue === '1' || prm.parameterValue.toLowerCase() === 'true');
    }
    return true;
  };
  const [isTakeAway] = useState<boolean>((state.cart?.deliveryMethod||DeliveryMethodEnum.FrontCounter)===DeliveryMethodEnum.Takeaway);
  const [methodFrontCounterInUse] = useState<boolean>(isMethodInUse(DeliveryMethodEnum.FrontCounter)||isMethodInUse(DeliveryMethodEnum.Takeaway));
  const [methotServicingInUse] = useState<boolean>(isMethodInUse(DeliveryMethodEnum.TableServing)||isMethodInUse(DeliveryMethodEnum.TakeawayTableServing));

  const proceedWithFrontCounter = async () => {
    if (state.cart){
      const cart = await setDeliveryMethod(state.cart.id, isTakeAway ? DeliveryMethodEnum.Takeaway : DeliveryMethodEnum.FrontCounter);
      dispatch({type: KioskActionsEnum.UPDATE_CART, cart});
      dispatch({type: KioskActionsEnum.SELECT_STEP, step: OrderStepsEnum.STEP_FOUR})
    }
  }

  const proceedWithServicingId = async () => {
    if (state.cart){
      dispatch({type: KioskActionsEnum.SELECT_STEP, step: OrderStepsEnum.STEP_THREE})
    }
  }

  if (isTakeAway){
    if (isMethodInUse(DeliveryMethodEnum.Takeaway) && !isMethodInUse(DeliveryMethodEnum.TakeawayTableServing)){
      proceedWithFrontCounter();
      return;
    }
  } else {
    if (isMethodInUse(DeliveryMethodEnum.FrontCounter) && !isMethodInUse(DeliveryMethodEnum.TableServing)){
      proceedWithFrontCounter();
      return;
    }
  }

  const selectAtCounter = (): void => {
    proceedWithFrontCounter();
  };
  const selectAtTable = (): void => {
    proceedWithServicingId();
  };
  
  return (
    <div className={styles.selectPlace}>
      {methodFrontCounterInUse && (
        <div 
          className={`container ${styles.placeCard}`} 
          onClick={() => selectAtCounter()}
        >
          <span>{t('button.deliverymethod-frontcounter')}</span>
        </div>
      )}
      {methotServicingInUse && (
        <div 
          className={`container ${styles.placeCard}`} 
          onClick={() => selectAtTable()}
        >
          <span>{t('button.deliverymethod-table')}</span>
        </div>
      )}
    </div>
  );
};