import SalePath from "./SalePath";
import ChildProductData from "./childProductData";
import Product from "./product";
import ProductData from "./productData";

export default class EditItem {

salePath: SalePath;
quantity: number = 1;
forcedPricing: boolean = false;

children: EditItem[];

product: Product | null = null;

savedState : EditItem | null = null;

constructor(
  salePath: SalePath,
  product?: Product | null
  ) {
    this.salePath = salePath;

    this.children = [];

    if (product && product.rowId?.length>0) this.product = product;

    const setChildren = (item: EditItem, salePath: SalePath): void => {
      salePath.children.forEach((salePathChild) => {
        var childProduct : Product | null = null;
        if (this.product != null && this.product.products?.length>0){
          const p = this.product.products.find((p:Product) => p.salePathCode===salePathChild.code);
          if (p)childProduct=p;
        }
        const editItemChild: EditItem = new EditItem(salePathChild, childProduct);
        item.children.push(editItemChild);
      });
    }

    setChildren(this, this.salePath);
    this.setDefaultValues();
    //console.log('editItem','constructor', this.salePath.name, this);
  }

  public reset = () => {
    const resetChildren = (item: EditItem): void => {
      item.setDefaultValues();
      item.children.forEach(e => resetChildren(e));
    }
    this.setDefaultValues();
    resetChildren(this);
  }
  
  public generateProductData = () : ProductData => {

    const updateChildren = (item: EditItem, quantity:number, productData: ProductData | ChildProductData): Array<ChildProductData> => {
      const productDataChildren: Array<ChildProductData> = [];
      item.children.filter((c:EditItem) => !(!c.salePath.isContent && c.quantity === 0)).forEach((salePathChild) => {
        //console.log('updateChildren', salePathChild.product?.productName, 'quantity', quantity, 'salePathQuantity', salePathChild.quantity);
        const price: number | null = (salePathChild.forcedPricing ? null : 0);
        const productDataChild: ChildProductData = new ChildProductData(
          salePathChild.salePath.productCode,
          salePathChild.quantity>0?1:0,
          '',
          price,
          salePathChild.salePath.conseptName,
          salePathChild.salePath.code,
          salePathChild.salePath.isContent,
          []);
        if (salePathChild.salePath.IsSelectOne()) productDataChild.quantity = 0;
        productDataChildren.push(productDataChild);
        updateChildren(salePathChild, quantity, productDataChild)
      });
      productData.childProducts = productDataChildren;
      return productDataChildren;
    }
    const result: ProductData = new ProductData(
      this.salePath.productCode,
      this.quantity,
      "",
      null,
      this.salePath.conseptName,
      this.salePath.code,
      this.salePath.isContent,
      []);
    updateChildren(this, result.quantity, result);
    //console.log('generateProductData', this.salePath.name, this, result);
    return result;
  }

  public findProductBySalePathCode = (product: Product, salePathCode: string) : Product | null => {
      if (product.salePathCode === salePathCode){
        return product;
      }
      for (let i = 0; i < product.products.length; i++) {
        const result = this.findProductBySalePathCode(product.products[i], salePathCode)
        if (result){
          return result;
        }
      }
      return null;
  }


  private static countQuantity = (root:EditItem) : number => {
    var total:number = root.quantity;
    root.children.forEach(c => total += this.countQuantity(c));
    return total;
  }

  private setDefaultValues = () => {
    //console.log('setDefaultValues', 'editItem', this)
    if (this.product){
      if (this.salePath.chainFreeUnits === 1){
        this.quantity = EditItem.countQuantity(this)>0?1:0;
      } else {
        this.quantity = this.product.quantity;
      }
      this.forcedPricing = this.product.salePrice !== 0;
      //if (this.product.quantity>0) console.log('setDefaultValues', 'product', this.product);
      //console.log('setDefaultValues', 'product', this.product);
    } else {
      if (this.salePath.isOptional){
        if (this.salePath.parent?.chainFreeUnits === 1){
          this.quantity = 0;
        } else {
          if (this.salePath.chainIsFreeOfCharge){
            this.quantity = 1;
          } else {
            this.quantity = 0;
          }
        }
      }
      else {
        this.quantity = 1;
      }
    }
    //console.log('setDefaultValues', this.salePath.name, this.product?.productName, this.quantity, this.forcedPricing, this.salePath.isOptional, this.salePath.chainFreeUnits, this.quantity);
  }

  public getForcedPrices = () : number => {
    var amount:number = this.forcedPricing===true?this.salePath.salePrice : 0;
    this.children.forEach(i => {
      amount += i.getForcedPrices();
    });
    return amount;
  }

}
