import { FC, useState } from 'react';
import SalePath from '../../../../models/SalePath';
import Radio from '@mui/material/Radio';
import styles from './styles.module.scss';
import noImagePng from '../../../../assets/images/no-image-icon.jpg';
import CheckIcon from '@mui/icons-material/CheckCircle';


interface Props {
  label: string,
  isOptional?: boolean,
  selected?: SalePath | null;
  choices: SalePath[];
  onChanged?: (selected: SalePath | null) => void;
}

const OptionSelect: FC<Props> = ({label, isOptional = false, selected = null, choices, onChanged}) => {
  const [ value, setValue ] = useState<SalePath|null>(selected);
  
  const labelClicked = (code: string): void => {
    selectionChanged(code);
  };

  const selectionChanged = (code: string): void => {
    const selectedValue: SalePath | undefined = choices.find(c => c.code === code);
    const newValue: SalePath | null = selectedValue === undefined ? null : selectedValue;
    if (isOptional && code===value?.code){
      if (value==null){
        setValue(newValue);
        if (onChanged) onChanged(newValue);
      } else {
        setValue(null);
        if (onChanged) onChanged(null);
      }
    } else {
      setValue(newValue);
      if (onChanged) onChanged(newValue);
    }
  };

  return (
    <div className={styles.optionSelect}>
      <div className={styles.content}>
        {choices.sort((n1, n2) => n1.orderNumber - n2.orderNumber).map((node, index) => (
          <div
            onClick={(): void => labelClicked(node.code)}
            className={`container ${styles.card} ${value === node ? styles.checked : ''}`} 
            key={index}
          >
            {value===node&& (<CheckIcon/>)}
            
            <div className={styles.image}>
              <img src={node.imagePath || noImagePng} alt={node.imagePath}/>
            </div>
            <div className={styles.name}>
              <h3 id={node.code}>{node.name}</h3>
            </div>
            <Radio
              sx={{display: 'none'}}
              checked={value === node}
              value={node.code}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default OptionSelect;
