import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ProductOptionDialog from "../Option/ProductOptionDialog";
import EditItem from '../../../models/EditItem';
import { useTranslation } from "react-i18next";
import { formatMoney } from "../../../helpers/formatHelper";
import ProductProperties from '../ProductProperties';


interface SalePathProductTitleProps {
  item: EditItem;
  isNeedUpdate: (bool: boolean) => void;
}

const SalePathProductTitle: FC<SalePathProductTitleProps> = ({item, isNeedUpdate}) => {
  const {t} = useTranslation();
  const [optionDialogOpen, setOptionDialogOpen] = useState<boolean>(false);
  const [isButtonPressed, setIsButtonPressed] = useState<boolean>(false);

  const openProductOptionDialog = (): void => {
    if (!item.salePath.isEditable()) return;
    setOptionDialogOpen(true);
  }

  useEffect((): void => {
    if (isButtonPressed) {
      setOptionDialogOpen(false)
      setIsButtonPressed(false)
      isNeedUpdate(true)
    }
  }, [isButtonPressed, isNeedUpdate]);
  
  const isContent = item.children.some(i => i.salePath.isContent);

  
  return (
    <div className={styles.salePathProduct}>

      <div className={`container ${styles.imageContainer}`}>
        <div className={styles.productImage}>
          <img src={item.salePath.imagePath} alt={item.salePath.imagePath}/>
          <div className={styles.price}>
            <span>{formatMoney(item.salePath.salePrice)} €</span>
          </div>
        </div>
        <div className={styles.productDescription}>
          <div>
            {/* <p className={styles.info}>{item.salePath.infoText}</p> */}
            <p className={styles.description}>{item.salePath.description}</p>
          </div>
          <ProductProperties node={item} />
          <div 
            className={isContent ? styles.buttonsIsContent : styles.buttons}>
            {isContent && 
              <button type='button' className='button' onClick={openProductOptionDialog}>{t('button.edit')}</button>
            }
            {/* <button type='button' className={`button ${styles.infoBtn}`}>{t('button.info')}</button> */}
          </div>
        </div>
      </div>
      
      <ProductOptionDialog
        item={item}
        open={optionDialogOpen}
        onClose={setIsButtonPressed}
      />
    </div>
  );
};

export default SalePathProductTitle;
