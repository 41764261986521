import { FC, ReactElement, useContext } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import SalePathNode from '../SalePathNode';
import SalePath from "../../../models/SalePath";
import styles from './styles.module.scss'
import { useTranslation } from "react-i18next";
import LanguageChoice from '../LanguageChoice';

const SalePathNodeCollection: FC = () => {
  const {state} = useContext(KioskContext);
  const {t} = useTranslation();
  const title: string = state.cart == null ? t('select-food') : t('continue');

  const sortedSalePaths: ReactElement<SalePath>[] = state.salePathData.rootItems
    .filter(p => p.isVisible && p.id > 0)
    .sort((n1: SalePath, n2: SalePath) => n1.orderNumber - n2.orderNumber)
    .map((node: SalePath, index: number) => <SalePathNode key={index} node={node}/>);

  const sortedSalePathsWithChildren: ReactElement<SalePath>[] | undefined = state.salePath?.children
    .filter(p => p.isVisible)
    .sort((n1: SalePath, n2: SalePath) => n1.orderNumber - n2.orderNumber)
    .map((node: SalePath, index: number) => <SalePathNode key={index} node={node}/>)

  return (
    <>
      {!state.salePath &&
        <h3 className={styles.title}>{title}</h3>
      }
      <div className={state.salePath ? styles.salePathContainer : styles.homePage}>
        {state.salePath ? sortedSalePathsWithChildren : sortedSalePaths}
      </div>
      {(state.salePath==null && state.cart==null) && (
      <div className={styles.languageContainer}>
        <div className={styles.languageTitle}><h2>{t('changeLanguage')}</h2></div>
        <LanguageChoice/></div>
      )}
    </>
  );
};

export default SalePathNodeCollection;
