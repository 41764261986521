import { useContext, useState } from 'react';
import styles from './styles.module.scss';
import { KioskContext } from "../../../../context/KioskContext";
import { KioskActionsEnum } from "../../../../context/KioskContext/types";
import NumericEntry from '../../NumericEntry';
import { DeliveryMethodEnum, setDeliveryMethod, setServingId } from '../../../../helpers/basketApi';
import { useTranslation } from 'react-i18next';

export const SelectTableServicing = () => {
  const {t} = useTranslation();
  const {state, dispatch} = useContext(KioskContext)

  const defaultValue = (): number|undefined => {
    if (state.cart && state.cart.servingID !== null && state.cart.servingID.length>0){
      try {
        return Number.parseInt(state.cart.servingID);
      }
      catch {
        return undefined;
      }
    }
    return undefined;
  };

  const [ numericValue, setNumericValue ] = useState<number|undefined>(defaultValue());
  const [isTakeAway] = useState<boolean>((state.cart?.deliveryMethod||DeliveryMethodEnum.FrontCounter)===DeliveryMethodEnum.Takeaway);

  const proceedWithServicingId = async (servicingId:string) => {
    if (state.cart){
      var cart = await setDeliveryMethod(state.cart.id, isTakeAway ? DeliveryMethodEnum.TakeawayTableServing : DeliveryMethodEnum.TableServing);
      cart = await setServingId(state.cart.id, servicingId);
      dispatch({type: KioskActionsEnum.UPDATE_CART, cart});
      dispatch({type: KioskActionsEnum.SELECT_NEXT_STEP})
    }
  }

  const allowToProceed: boolean = (numericValue !== undefined && numericValue>0);

  const handleProceed = (): void => {
    if (allowToProceed&&numericValue){
      proceedWithServicingId(numericValue.toString());
    }
  };
  
  return (
    <div className={styles.selectTableServicing}>
      <div className={`container ${styles.placeCard}`} onClick={handleProceed}>
        <span>{numericValue}</span>
      </div>
      <div className={styles.keypad} >
        <NumericEntry value={numericValue} onValueChange={(value) => setNumericValue(value)}/>
      </div>
      <button className={`${styles.confirmButton} mainButton ${(!allowToProceed) ? styles.disabledBtn : ''}`} onClick={handleProceed} disabled={!allowToProceed}>{t('button.confirm')}</button>      
    </div>
  );
};