import { useContext } from 'react';
import styles from './styles.module.scss';
import { KioskContext } from "../../../../context/KioskContext";
import { KioskActionsEnum } from "../../../../context/KioskContext/types";
import { formatMoney } from "../../../../helpers/formatHelper";
import { useTranslation } from "react-i18next";
import BasketProductList from '../../BasketProductList';


export const ConfirmStep = () => {
  const {t} = useTranslation();
  const {state, dispatch} = useContext(KioskContext)
  
  const handlePayment = (): void => {
    dispatch({type: KioskActionsEnum.START_PAYMENT})
    dispatch({type: KioskActionsEnum.SELECT_NEXT_STEP})
  };
  
  return (
    <div className={styles.confirmStep}>
      <div className={`container ${styles.contentContainer}`}>
        <BasketProductList/>
      </div>
      <div className={styles.paymentButton}>
        <button className={`mainButton`} onClick={handlePayment} disabled={!((state.cart?.receiptTotal||0)>0)}>{t('pay-the-order')} {formatMoney(state.cart?.receiptTotal)} €</button>
      </div>
    </div>
  );
};