import * as React from 'react';
import { getLocalSettings, setLocalSettings, } from '../../helpers/localStorageHelper';

import {
  BasketEnum,
  KioskActionsEnum,
  KioskReducer,
  IKioskContext,
  OrderStepsEnum,
} from "./types";
import SalePathData from '../../models/SalePathData';
import EditData from '../../models/EditData';
import ILocalSettings from '../../models/localSettings';
import { DeliveryMethodEnum } from '../../helpers/basketApi';

export const initialState: IKioskContext = {
  operator: null,
  settings: getLocalSettings(),
  isBrowsing: true,
  orderSteps: 0,
  isDataLoaded: false,
  isConfirmingBasket: false,
  salePath: null,
  salePathData: new SalePathData([]),
  editData: null,
  cart: null,
  loadedAt: null,
  paymentPending: null,
  paymentInProcess: null,
};


export const KioskContext = React.createContext<{
  state: IKioskContext;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer: KioskReducer = (state: IKioskContext, action: any) => {

  switch (action.type) {
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
    
    case KioskActionsEnum.LOADED: {
      return {
        ...state,
        isDataLoaded: true,
        salePathData: new SalePathData(action.salePaths),
        editData: null,
        loadedAt: new Date(),
      };
    }

    case KioskActionsEnum.LOGIN_USER: {
      return {
        ...state,
        operator: action.operator,
        inactive: false,
      };
    }

    case KioskActionsEnum.SET_SETTINGS: {
      const data: ILocalSettings = action.settings;
      const success: boolean = setLocalSettings(data);
      if (success) {
        return {
          ...state,
          settings: data,
        };
      }
      return {
        ...state,
        settings: null,
      };
    }

    case KioskActionsEnum.TIMEOUT: {
      return {
        ...state,
        isConfirmingBasket: false,
        salePath: null,
        editData: null,
        cart: null,
        subTitleText: '',
        orderSteps: 0
      };
    }

    case KioskActionsEnum.RELOAD: {
      return {
        ...state,
        isDataLoaded: false,
      };
    }

    case KioskActionsEnum.UPDATE_CART: {
      return {
        ...state,
        cart: action.cart,
      };
    }

    case KioskActionsEnum.SELECT_NODE: {
      let editData: EditData | null = null;
      if (action.salePath.isMainProduct()) editData = new EditData(action.salePath, action.product);
      return {
        ...state,
        salePath: action.salePath,
        editData: editData,
      };
    }
    
    case KioskActionsEnum.SELECT_PARENT: {
      return {
        ...state,
        salePath: state.salePath ? state.salePath.parent : null,
      };
    }
    
    case KioskActionsEnum.SELECT_ROOT: {
      return {
        ...state,
        salePath: null,
      };
    }
    
    case KioskActionsEnum.SHOW_BASKET: {
      return {
        ...state,
        isConfirmingBasket: true
      };
    }
    
    case BasketEnum.HIDE_BASKET: {
      return {
        ...state,
        isConfirmingBasket: false
      };
    }
    
    case BasketEnum.REMOVE_ORDER: {
      return {
        ...state,
        salePath: null,
        editData: null,
        cart: null,
      };
    }

    case KioskActionsEnum.SELECT_NEXT_STEP: {
      const step: number = state.orderSteps + 1;
      return {
        ...state,
        orderSteps: Number.isInteger(action.step)?action.step:step
      }
    }

    case KioskActionsEnum.SELECT_PREVIOUS_STEP: {
      let step: number = state.orderSteps - 1;
      if (state.orderSteps=== 4 && state.cart?.deliveryMethod !== DeliveryMethodEnum.TakeawayTableServing && state.cart?.deliveryMethod !== DeliveryMethodEnum.TableServing){
        step--;
      }
      return {
        ...state,
        orderSteps: step
      }
    }

    case KioskActionsEnum.SELECT_STEP: {
      return {
        ...state,
        orderSteps: action.step,
      }
    }
    
    case KioskActionsEnum.START_PAYMENT: {
      return {
        ...state,
        paymentPending: true,
        paymentInProcess: false,
      }
    }
    
    case KioskActionsEnum.PAYMENT_INPROCESS: {
      return {
        ...state,
        paymentPending: false,
        paymentInProcess: true,
      }
    }
    
    case KioskActionsEnum.PAYMENT_FAILED: {
      return {
        ...state,
        paymentPending: false,
        paymentInProcess: false,
      }
    }
    
    case KioskActionsEnum.PAYMENT_SUCCESSFUL: {
      return {
        ...state,
        orderSteps: OrderStepsEnum.STEP_SIX,
        paymentPending: false,
        paymentInProcess: false,
      }
    }

    case KioskActionsEnum.ORDER_COMPLETE: {
      return {
        ...state,
        orderSteps: 0,
        totalBasketPrice: null,
        salePath: null,
        editData: null,
        cart: null,
      }
    }
  }
};

interface KioskProviderProps {
  children: React.ReactNode
}
export const KioskProvider: React.FC<KioskProviderProps> = ({ children }) => {

  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <KioskContext.Provider value={{ state, dispatch }}>
      {children}
    </KioskContext.Provider>
  );
};