import { FC, ReactNode } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import LanguageIcon from '@mui/icons-material/LanguageOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './styles.module.scss';

export enum IconType {
  Home = 1,
  Back,
  Basket,
  Clear,
  Language
}

interface SideMenuButtonProps {
  label: string,
  icon?: IconType,
  disabled?: boolean,
  onClick: () => void,
}

const SideMenuButton: FC<SideMenuButtonProps> = ({label, icon, disabled = false, onClick}) => {

  const clickHandler = (): void => {
    if (!disabled) {
      !disabled && onClick(); 
    }
  }

  const lines: string[] = label.split('|');

  const renderIcon = (): ReactNode => {
    const size:string = '4.5vw';
    switch (icon) {
      default:
        return <></>;
      case IconType.Home:
        return <HomeOutlinedIcon style={{fontSize: size}}/>;
      case IconType.Back:
        return <KeyboardBackspaceOutlinedIcon style={{fontSize: size}}/>;
      case IconType.Basket:
        return <ShoppingBasketOutlinedIcon style={{fontSize: size}}/>;
      case IconType.Clear:
        return <ClearIcon style={{fontSize: size}}/>;
        case IconType.Language:
          return <LanguageIcon style={{fontSize: size}}/>;
      }
  }
  
    return (
      <div onClick={clickHandler} className={`${styles.sideBarMenuItem} ${IconType[icon||IconType.Home].toLowerCase()}`}>
        <div className={disabled ? styles.itemContentDisabled : styles.itemContent}>
          {icon && renderIcon()}
          <div className={styles.itemLabel}>
            {lines.map((l,i) => (<span key={i}>{l}</span>))}
          </div>
        </div>
      </div>
    );
};

export default SideMenuButton;
