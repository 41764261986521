import Cart from '../models/cart';
import { getConfig } from './apiConfig';
import ProductData from '../models/productData';
import ILocalSettings from '../models/localSettings';
import { handleApiError } from './loggerApi';

export enum DeliveryMethodEnum {
  FrontCounter = 'FrontCounter',
  TableService = 'TableService',
  TableServing = 'TableServing',
  TakeawayTableServing = 'TakeawayTableServing',
  Takeaway = 'Takeaway',
  Curbside = 'Curbside'
}

const apiUrl:string = getConfig().apiUrl;

export const getCart = async (cartId: string): Promise<Cart> => {
  try {
    const response = await fetch(`${apiUrl}/cart/${cartId}`, {
    });
    if (response.ok) {
      return await response.json();
    } else {
      handleApiError('getCart', response, cartId);
      return Promise.reject(await response.json());
    }
  } catch (error) {
    handleApiError('getCart', error, cartId);
    throw error;
  }
};

export const initializeCart = async (
  settings: ILocalSettings,
): Promise<Cart> => {
  try {
    const response = await fetch(`${apiUrl}/cart`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shopCode: settings.shopCode,
        posNumber: settings.posCode,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      handleApiError('initializeCart', response);
      return Promise.reject(await response.json());
    }
  } catch (error) {
    handleApiError('initializeCart', error, undefined, undefined);
    throw error;
  }
};

export const addProductRow = async (
    cartId: string,
    product: ProductData,
): Promise<Cart> => {
  try {
    const response = await fetch(`${apiUrl}/cart/${cartId}/products`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(product),
      });
      if (response.ok) {
        return response.json();
      } else {
        handleApiError('addProductRow', response, cartId, {product:product});
        return Promise.reject(response.json());
      }
  } catch (error) {
    handleApiError('addProductRow', error, cartId, {product:product});
    throw error;
  }
};

export const deleteProductRow = async (
    cartId: string,
    rowId: string,
): Promise<Cart> => {
  try {
    const response = await fetch(`${apiUrl}/cart/${cartId}/products/${rowId}`, {
      method: 'delete',
    });
    if (response.ok) {
      return await response.json();
    } else {
      handleApiError('deleteProductRow', response, cartId, {rowId:rowId});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('deleteProductRow', error, cartId, {rowId:rowId});
    throw error;
  }
};

export const getServiceId = async (
    cartId: string,
): Promise<Cart> => {
  try {
    const response = await fetch(`${apiUrl}/cart/${cartId}/serviceid`, {
    });
    if (response.ok) {
      return await response.json();
    } else {
      handleApiError('getServiceId', response, cartId);
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('getServiceId', error, cartId);
    throw error;
  }
};

export const setDeliveryMethod = async (
    cartId: string,
    deliveryMethod: DeliveryMethodEnum,
): Promise<Cart> => {
  try {
    const response = await fetch(`${apiUrl}/cart/${cartId}/deliverymethod`, {
      method: 'post',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(deliveryMethod),
    });
    if (response.ok) {
      return await response.json();
    } else {
      handleApiError('setDeliveryMethod', response, cartId, {deliveryMethod:deliveryMethod});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('setDeliveryMethod', error, cartId, {deliveryMethod:deliveryMethod});
    throw error;
  }
};

export const setServingId = async (
  cartId: string,
  servingId: string,
): Promise<Cart> => {
  try {
    const response = await fetch(`${apiUrl}/cart/${cartId}/servingid`, {
      method: 'post',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(servingId),
    });
    if (response.ok) {
      return await response.json();
    } else {
      handleApiError('setServingId', response, cartId, {servingId:servingId});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('setServingId', error, cartId, {servingId:servingId});
    throw error;
  }
};

export const getReceiptLayout = async (
  cartId: string,
): Promise<string> => {
  try {
    const response = await fetch(`${apiUrl}/cart/${cartId}/receipt/download/xml`, {
      method: 'post',
      headers: {
          'Content-Type': 'application/json',
      }
    });
    if (response.ok) {
      return await response.text();
    } else {
      handleApiError('getReceipt', response, cartId, {format:'xml'});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('getReceipt', error, cartId, {format:'xml'});
    throw error;
  }
};
