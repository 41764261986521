import { FC } from 'react';
import { DarkMode, LightMode } from "@mui/icons-material";
import styles from './styles.module.scss'

const ThemeChoice: FC = () => {

  const key:string = 'data-theme';
  const defaultTheme:string = 'light';

  const theme:string = document.documentElement.getAttribute('data-theme')||defaultTheme;

  const changeTheme = () => {
    if (theme === 'light'){
      document.documentElement.setAttribute(key, 'dark')
    } else {
      document.documentElement.setAttribute(key, 'light')
    }
  }

  return (
    <div className={styles.themeChoice} onClick={changeTheme}>
      {theme==='light'?<DarkMode style={{fontSize: '3vw', color:'black'}}/>:<LightMode style={{fontSize: '3vw', color:'whitesmoke'}}/>}
    </div>
  );
};

export default ThemeChoice;
