import { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import EditItem from '../../../models/EditItem';

interface Props {
  node: EditItem
}

const ProductProperties: FC<Props> = ({node}) => {
  const {t} = useTranslation();

  const buildAllergensText = () : string => {
    const items: string[] = [];
    node.salePath.allergens.forEach(a => {
      items.push(` ${t(`allergens.${a}_name`)}. `);
    })
    return items.join('');
  }
  return (
    <div className={styles.productProperties}>
      {node.salePath.specialDiets.length>0 && 
      <>
        <div className={styles.title}>
          {t(`specialDiets.title`)}
        </div>
        <div className={styles.specialDiets}>
          {node.salePath.specialDiets.map((name: string, i: number) => (
            <div key={i} className={styles.specialDiet}>
              {t(`specialDiets.${name}_description`)}
            </div>
          ))}
        </div>
      </>
      }
      {node.salePath.allergens.length>0 && 
      <>
        <div className={styles.title}>
          {t(`allergens.title`)}
        </div>
        <div className={styles.allergens}>
          {buildAllergensText()}
        </div>
      </>
      }
    </div>
  );
};

export default ProductProperties;