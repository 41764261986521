import { FC } from 'react';
import PestControlOutlinedIcon from '@mui/icons-material/PestControlOutlined';
import styles from './styles.module.scss'
import Config from '../../../hooks/Config';

const DebugToggle: FC = () => {

  const {toggleDebugMode} = Config();

  return (
    <div className={styles.debugToggle}>
      <div onClick={toggleDebugMode}>
        <PestControlOutlinedIcon style={{fontSize: '3vw'}}/>
      </div>
    </div>
  );
};

export default DebugToggle;
