import { FC } from 'react';
import styles from './styles.module.scss'
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const ScreenSize: FC = () => {

  const dimensions = useWindowDimensions();

  return (
    <div className={styles.screenSize}>
      {`${dimensions.width} x ${dimensions.height}`}
    </div>
  );
};

export default ScreenSize;
